import React, { useState } from "react";
import { PlayArrow, Pause, Favorite, Share } from "@mui/icons-material";
import { Box, IconButton, Grid, Typography } from "@mui/material";
import { PROD_IMG_FUNC } from "../../BaseUrl";

const SingleVideo = ({ vid, ratio, radius }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = React.useRef(null);

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef?.current?.pause();
    } else {
      videoRef?.current?.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "90%",
        maxWidth: 300,
        aspectRatio: ratio || "9/16",
        mx: "auto",
        mb: 4,
        bgcolor: "black",
        overflow: "hidden",
        borderRadius: radius || 4,
        boxShadow: 3,
        "&:hover": {
          cursor: "pointer",
          "& .play-pause-button": {
            opacity: 1, // Show the button on hover
          },
        },
      }}

    >
      <video
        ref={videoRef}
        // src={ `${PROD_IMG_FUNC(vid.User.secure_id)}${vid.video_url}`}
        src={PROD_IMG_FUNC(vid.User.secure_id) + vid.video_url}
        style={{ width: "100%", height: "100%", objectFit: "cover" }}
        autoPlay
        muted
        loop
        playsInline
        // muted
        onClick={handlePlayPause}
      ></video>

      {/* Play/Pause Button */}
      <IconButton
        className="play-pause-button"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: "white",
          opacity: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.7)" },
        }}
        onClick={handlePlayPause}
      >
        {isPlaying ? <Pause sx={{ fontSize: 30 }} /> : <PlayArrow sx={{ fontSize: 30 }} />}
      </IconButton>

      {/* Like and Share */}
      <Box
        sx={{
          position: "absolute",
          top: 8,
          right: 8,
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        {/* <IconButton
          sx={{
            bgcolor: "rgba(255, 255, 255, 0.8)",
            '&:hover': { bgcolor: "white" },
            color: "black",
          }}
        >
          <Favorite fontSize="small" />
        </IconButton>
        <IconButton
          sx={{
            bgcolor: "rgba(255, 255, 255, 0.8)",
            '&:hover': { bgcolor: "white" },
            color: "black",
          }}
        >
          <Share fontSize="small" />
        </IconButton> */}
      </Box>
    </Box>
  );
};

export default SingleVideo;



