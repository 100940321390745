import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import img from "../../assets/dukan/image 164.png";
import { useNavigate, useParams } from "react-router-dom";

const Cards = ({categoryInfo}) => {
  const navigate = useNavigate();
  
  return (
    <Box sx={{ width: "100%", height: "100%",'&:hover':{cursor:"pointer"} }}>
      <Box
        sx={{
          width: "100%",
          padding: "32px",
          backgroundColor: "#C7DEEF",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            fontSize: { md: "16px", xs: "18px" },
            fontWeight: { md: "700", xs: "500" },
            fontFamily: "Montserrat",
            color: "#ff944e",
          }}
        >
          {categoryInfo ?.title}
        </Typography>
        {/* <Typography
          sx={{
            fontSize: { md: "58px", xs: "32px" },
            textAlign: "center",
            fontWeight: "700",
            fontFamily: "Montserrat",
          }}
        >
          {categoryInfo?.description}
        </Typography> */}
      </Box>
      {/* <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { md: "1fr 1fr 1fr 1fr 1fr 1fr", xs: "1fr 1fr" },
          gap: { md: "25px", xs: "21px" },
          padding: { md: "35px 77px", xs: "12px 24px" },
        }}
      >
        {[1, 2, 3, 4, 5, 6].map((data) => (
          <Box
            onClick={() => navigate("/mainCategory")}
            key={data}
            sx={{ width: "100%", height: "100%", position: "relative" }}
          >
            <Box
              component="img"
              src={img}
              sx={{ width: "100%", height: "100%" }}
            />
            <Typography
              sx={{
                position: "absolute",
                bottom: "10%",
                left: "25%",
                color: "white",
                fontWeight: "700",
                fontFamily: "Montserrat",
                fontSize: { md: "16px", xs: "14px" },
              }}
            >
              Mens Formal
            </Typography>
          </Box>
        ))}
      </Box> */}
    </Box>
  );
};

export default Cards;
