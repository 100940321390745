import React from "react";
import { Container, Typography, Box, Paper, Divider } from "@mui/material";
import GavelIcon from "@mui/icons-material/Gavel";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Link from "@mui/material/Link";
import HeaderSection from "../registerShop/HeaderSection";
import Footer from "../HomePage/Footer";
const TermOfUsage = () => {
  const handleDownloadPDF = () => {
    const pdfUrl =
      "https://indiazona-assets.blr1.cdn.digitaloceanspaces.com/assets/images-prod/Terms%20of%20Use.pdf";
    window.open(pdfUrl, "_blank");
  };

  return (
    <>
      <HeaderSection />
      <Container sx={{ py: 4 }}>
        <Paper
          elevation={3}
          sx={{
            p: 4,
            borderRadius: 3,
            background: "linear-gradient(135deg, #f5f7fa 0%, #f3f4f6 100%)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mb: 3,
            }}
          >
            <GavelIcon sx={{ fontSize: 40, color: "primary.main", mr: 2 }} />
            <Typography variant="h4" color="primary" fontWeight="bold">
              Terms of Usage
            </Typography>
          </Box>

          <Divider sx={{ mb: 3, borderColor: "primary.main" }} />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Typography variant="body1" paragraph>
              This document is an electronic record in terms of Information
              Technology Act, 2000 and rules there under as applicable and the
              amended provisions pertaining to electronic records in various
              statutes as amended by the Information Technology Act, 2000. This
              electronic record is generated by a computer system and does not
              require any physical or digital signatures.
              <br />
              <br /> This document is published in accordance with the
              provisions of Rule 3 (1) of the Information Technology
              (Intermediaries guidelines) Rules, 2011 that require publishing
              the rules and regulations, privacy policy and Terms of Use for
              access or usage of domain name [www.IndiaZona.com] ("Website"),
              including the related mobile site and mobile application
              (hereinafter referred to as "Platform")
              <br />
              <br /> The Platform is owned by Mahakali Infotek, a partnership
              registered under the partnership act with its registered oce at 11
              Clive Row, Kolkata, India (hereinafter referred to as
              "IndiaZona").
              <br />
              <br />
              Your use of the Platform and services and tools are governed by
              the following terms and conditions ("Terms of Use") as applicable
              to the Platform including the applicable policies which are
              incorporated herein by way of reference. If You transact on the
              Platform, You shall be subject to the policies that are applicable
              to the Platform for such transaction. By mere use of the Platform,
              You shall be contracting with Mahakali Infotek and these terms and
              conditions including the policies constitute Your binding
              obligations, with IndiaZona. <br />
              <br />
              For the purpose of these Terms of Use, wherever the context so
              requires "You" or "User" shall mean any natural or legal person
              who has agreed to become a buyer on the Platform by providing
              Registration Data while registering on the Platform as Registered
              User using the computer systems. IndiaZona allows the User to surf
              the Platform or making purchases without registering on the
              Platform. The term "We", "Us", "Our" shall mean Mahakali infotek.
              <br />
              <br />
              ACCESSING, BROWSING OR OTHERWISE USING THE SITE INDICATES YOUR
              AGREEMENT TO ALL THE TERMS AND CONDITIONS UNDER THESE TERMS OF
              USE, SO PLEASE READ THE TERMS OF USE CAREFULLY BEFORE PROCEEDING.
              By impliedly or expressly accepting these Terms of Use, You also
              accept and agree to be bound by IndiaZona Policies (including but
              not limited to Privacy Policy available at Privacy) as amended
              from time to time.
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 3,
              }}
            >
              <Link
                component="button"
                onClick={handleDownloadPDF}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 1,
                  color: "primary.main",
                  textDecoration: "underline",
                  fontSize: "1rem",
                  "&:hover": {
                    color: "primary.dark",
                  },
                }}
              >
                Read Full Terms and Conditions
                <OpenInNewIcon fontSize="small" />
              </Link>
            </Box>
          </Box>
        </Paper>
      </Container>
      <Footer />
    </>
  );
};

export default TermOfUsage;