import React from "react";
import ShowEverything from "../components/Dukan/ShowEverything";
import Trend from "../components/HomePage/Trend";
import { Typography } from "@mui/material";
import Feature from "../components/HomePage/Feature";
import Hero from "../components/HomePage/Hero";
import Brand from "../components/Dukan/Brand";
import ShopByProduct from "../components/Dukan/ShopByProduct";
import Expolore from "../components/Dukan/Expolore";
import DukanProducts from "../components/Dukan/DukanProducts";

const Dukan = () => {
  return (
    <div>
      <ShowEverything />
      <DukanProducts/>
      <Expolore/>
      <Trend />
      {/* <ShopByProduct/> */}
      {/* <Brand/> */}
      {/* <Typography
        sx={{
          textAlign: "center",
          fontWeight: "600",
          fontSize: { md: "42px", xs: "20px" },
          fontFamily: "Montserrat",
        }}
      >
        View Product keep shoping
      </Typography> */}
      <Feature />
      <Hero />
    </div>
  );
};

export default Dukan;
