import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import bucket from '../../assets/dukan/bucket.png'
import astetic from '../../assets/dukan/astetic.png'
import Camel from '../../assets/dukan/camel.png'
import madeInIndia from '../../assets/dukan/madeinIndia.png'
import { useNavigate } from 'react-router'

const Expolore = () => {
  const navigate = useNavigate()
  return (
    <Box sx={{padding:{md:'86px 56px 100px 56px',xs:'16px 8px 39px 8px',width:'100%'}}}>
    <Box sx={{display:'flex',alignItems:"flex-start",justifyContent:'space-between'}}>
    <Typography sx={{textAlign:'start',marginBottom:{md:'32px',xs:"20px"},fontSize:{md:'28px',xs:'16px'},fontWeight:'600',fontFamily:'Montserrat'}}>Expolore</Typography>
    <Typography sx={{color:'primary.main',textDecoration:'underline',fontSize:{md:'18.58px',xs:"12px"},fontWeight:'600',fontFamily:'montserrat'}}>see All</Typography>
    </Box>

   <Box sx={{display:'grid',gridTemplateColumns:{md:'2fr 1fr 1fr',xs:'1fr'},gap:{md:'30px',xs:'13px'}}}>

   <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    //   width: {md:'637px',xs:'207px'},
      height:{md:'700px',xs:'385px'},
      position: 'relative', // Set the parent Box to relative
      overflow: 'hidden',
    }}
  >
    {/* Image */}
    <Box
      component="img"
      src={bucket}
      alt="Description of image" // Always include alt text for accessibility
      sx={{
        width: '100%',
        height:{md:'700px',xs:'385px'},
        objectFit: 'cover', // Ensures the image covers the Box without distortion
      }}
    />
 
    <Button onClick={()=>navigate('/category')} sx={{ padding:{md:'12px 94px'},position:'absolute',fontFamily:'Montserrat',backgroundColor:'primary.contrastText',bottom:'5%',left:'5%',fontWeight:'600',fontSize:{md:'16px',xs:'20px'}}}>HAND MADE</Button>
  </Box>

  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height:{md:'700px',xs:'385px'},
      position: 'relative', // Set the parent Box to relative
      overflow: 'hidden',
    }}
  >
    {/* Image */}
    <Box
      component="img"
      src={astetic}
      alt="Description of image" // Always include alt text for accessibility
      sx={{
        width: '100%',
        height:{md:'700px',xs:'385px'},
        objectFit: 'cover', // Ensures the image covers the Box without distortion
      }}
    />
    
  
    <Button sx={{ padding:{md:'12px 94px'},position:'absolute',fontFamily:'Montserrat',backgroundColor:'primary.contrastText',bottom:'5%',left:'5%',fontWeight:'600',fontSize:{md:'16px',xs:'20px'}}}>AESTHETIC</Button>
  </Box>


  <Box sx={{display:'grid',gridTemplateRows:'1fr 1fr',gap:'22px'}}>

<Box
 sx={{
   display: 'flex',
   alignItems: 'center',
   justifyContent: 'center',
   width: '100%',
   height:{md:'339px',xs:'192px'},
   position: 'relative', // Set the parent Box to relative
   overflow: 'hidden',
 }}
>
 {/* Image */}
 <Box
   component="img"
   src={Camel}
   alt="Description of image" // Always include alt text for accessibility
   sx={{
    width: '100%',
    height:{md:'339px',xs:'192px'},
     objectFit: 'cover', // Ensures the image covers the Box without distortion
   }}
 />
 

 <Button sx={{padding:{md:'12px 94px'},position:'absolute',fontFamily:'Montserrat',backgroundColor:'white',bottom:'5%',left:'5%',fontWeight:'600',fontSize:{md:'16px',xs:'20px'}}}>TRADITIONAL</Button>
</Box>

 <Box
 sx={{
   display: 'flex',
   alignItems: 'center',
   justifyContent: 'center',
   width: '100%',
   height:{md:'339px',xs:'192px'},
   position: 'relative', // Set the parent Box to relative
   overflow: 'hidden',
 }}
>
 {/* Image */}
 <Box
   component="img"
   src={madeInIndia}
   alt="Description of image" // Always include alt text for accessibility
   sx={{
    width: '100%',
    height:{md:'339px',xs:'192px'},
     objectFit: 'cover', // Ensures the image covers the Box without distortion
   }}
 />
 

 <Button sx={{ padding:{md:'12px 94px'},position:'absolute',fontFamily:'Montserrat',backgroundColor:'white',bottom:'5%',left:'5%',fontWeight:'700',fontSize:{md:'16px',xs:'20px'}}}>MADE IN INDIA</Button>
</Box>
</Box>

  </Box>
  </Box>
  )
}

export default Expolore