import React, { useEffect, useRef, useState } from "react";
import indiazonaIcon from "../../assets/indianzonaIcon.svg";
import lionIcon from "../../assets/lionIcon.svg";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { 
  Logout, 
  Settings, 
  Person 
} from '@mui/icons-material';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  TextField,
  Typography,
  Menu, 
  MenuItem, 
  ListItemIcon, 
  ListItemText 
} from "@mui/material";
import MobileHeader from "./MobileHeader";
import { useNavigate } from "react-router-dom";
import { get } from "../../services";
import { IN_PRODUCT } from "../../BaseUrl";
import axios from "axios";
import LocalstorageService from "../../utils/helpers/localstorage-services";

const Header = () => {
  const data = ["Mobile", "cooker", "shoes", "tshirt", "home"];
  const [user, setUser] = React.useState(null);
  const getUserDetails = async () => {
    try {
      const token = localStorage.getItem("authToken");

      if (!token) {
        throw new Error("No authentication token found");
      }

      const response = await axios.get(
        `https://seashell-app-2jxuz.ondigitalocean.app/api/v1/app/user/get-profile-details`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setUser(response.data);
      return response.data;
    } catch (error) {
      console.error("Failed to fetch user details:", error);

      if (error.response && error.response.status === 401) {
        localStorage.removeItem("token");
        setUser(null);
      }

      throw error;
    }
  };

  const handleLogout = () => {
    // Logout logic
    console.log('Logout');
    localStorage.removeItem('authToken'); // Remove the token from local storage
    // LocalstorageService.setInLocalStorage(USER_AUTH_TOKEN_KEY, response.data.token);
    LocalstorageService.logoutUser();
    window.location.reload();
    // navigate('/login')
};


  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      getUserDetails();
    }
  }, []);

  const navigate = useNavigate();

  // State to manage the input value
  const [inputValue, setInputValue] = React.useState("");
  const [suggestions, setSuggestions] = React.useState([]);
  const searchBoxRef = useRef(null);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openmenu = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

    
  const handleSearch = (value) => {
    setInputValue(value);
    if (value.length > 0) {
      const params = {
        query: value,
      };
      get(IN_PRODUCT + "search", { params })
        .then((res) => {
          const { data } = res;
          console.log(data);
          setSuggestions(data.data);
          setOpen(true);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setSuggestions([]);
      setOpen(false);
    }
  };

  useEffect(() => {
    const handleClickAway = (event) => {
      if (
        searchBoxRef.current &&
        !searchBoxRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickAway);
    return () => {
      document.removeEventListener("mousedown", handleClickAway);
    };
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{
          padding: "11px 13px",
          display: { xs: "none", md: "flex" },
          alignItems: "center",
          backgroundColor: "background.paper",
          justifyContent: "space-between",
          height: "fit-content",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItem: "center",
            gap: "10px",
            width: "fit-content",
            height: "fit-content",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItem: "center",
              width: "189px",
              height: "65px",
              overflow: "hidden",
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={(e) => navigate("/")}
          >
            <Box component="img" alt="indiazona" src={indiazonaIcon} />
          </Box>
          <Divider sx={{ width: "2px", backgroundColor: "background.grey" }} />
          <Box
            sx={{
              display: "flex",
              alignItem: "center",
              width: "141px",
              height: "72px",
              overflow: "hidden",
            }}
          >
            <Box component="img" alt="lionIcon" src={lionIcon} />
          </Box>
        </Box>
        {/* <----------search bar----------------> */}
        {/* <Box sx={{ width: "fit-content", height: "fit-content" }}>
          <Autocomplete
            // Controlled state for the selected value
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            // Controlled state for the input value
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            // Options to display in the dropdown
            options={data}
            // Function to disable specific options
            // getOptionDisabled={(option) => data.includes(option)}
            // Hide the dropdown arrow by setting popupIcon to null
            popupIcon={null}
            // Function to disable specific options

            // Filter options based on input
            filterOptions={(options, { inputValue }) =>
              options.filter((option) =>
                option.toLowerCase().includes(inputValue.toLowerCase())
              )
            }
            // Define the width and responsiveness
            sx={{
              width: {
                xs: '100%',   // Full width on extra-small screens
                sm: '75%',    // 75% width on small screens
                md: '562px',  // Fixed width on medium and larger screens
              },
              '& .MuiAutocomplete-listbox': {
                // Hide scrollbar for Chrome, Safari, and Opera
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
                // Hide scrollbar for Firefox
                scrollbarWidth: 'none',
                // Hide scrollbar for IE, Edge
                '-ms-overflow-style': 'none',
              },
            }}
            renderOption={(props, option) => (
              <li {...props} key={option}>
                <Box sx={{ display: 'flex',gap:"20px", alignItems: 'center' }}>
                <SearchIcon
                            sx={{
                              width: "20px",
                              height: "20px",
                            }}
                          />
                          <Typography>
                  {option}

                          </Typography>
                </Box>
              </li>
            )}
          
            // Render the customized TextField
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search..."
                variant="outlined"
                fullWidth
                aria-label="Search"
                onChange={(e)=>handleSearchSuggestion(e.target.value)}
                // Customize the InputProps to include the search icon
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {params.InputProps.endAdornment}
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleSearch}
                          edge="end"
                          aria-label="search"
                          sx={{
                            width: "32px",
                            height: "32px",
                            borderRadius: "5px",
                            backgroundColor: "primary.main",
                            padding: "6px",
                            "&:hover": {
                              backgroundColor: "primary.dark", // Optional: change color on hover
                            },
                          }}
                        >
                          <SearchIcon
                            sx={{
                              width: "20px",
                              height: "20px",
                              color: "primary.contrastText",
                            }}
                          />
                        </IconButton>
                      </InputAdornment>
                    </>
                  ),
                  sx: {
                    // Remove the default outline and border
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid",
                      borderColor: "primary.light",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid",
                      borderColor: "primary.light",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid",
                      borderColor: "primary.light",
                    },
                    // Set background color
                    backgroundColor: "customBg.lightBlue",
                    // Set height
                    height: "40px",
                    // Adjust the input padding to center the text vertically
                    "& .MuiOutlinedInput-input": {
                      padding: "10px 14px",
                      height: "20px", // Adjust as needed
                    },
                  },
                }}
                // Apply additional styles to the TextField
                sx={{
                  // Ensure the Autocomplete maintains the desired height
                  "& .MuiInputBase-root": {
                    height: "40px",
                  },
                }}
              />
            )}
          />
        </Box> */}

        <Box
          sx={{ width: "100%", maxWidth: "562px", position: "relative" }}
          ref={searchBoxRef}
        >
          <TextField
            value={inputValue}
            onChange={(e) => handleSearch(e.target.value)}
            placeholder="Search..."
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: (
                <Box sx={{ display: "flex", alignItems: "center", pl: 1 }}>
                  <SearchIcon />
                </Box>
              ),
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "5px",
                height: "40px",
                backgroundColor: "customBg.lightBlue",
                "& .MuiOutlinedInput-input": {
                  padding: "10px 14px",
                },
              },
            }}
          />
          {open && suggestions.length > 0 && (
            <List
              sx={{
                position: "absolute",
                top: "42px",
                left: 0,
                width: "100%",
                maxHeight: "200px",
                overflowY: "auto",
                backgroundColor: "white",
                boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                zIndex: 10,
                borderRadius: "5px",
              }}
            >
              {suggestions.map((option, index) => {
                if (option.type === "product") {
                  return (
                    <ListItem
                      key={index}
                      button
                      onClick={(e) =>
                        window.open(`/product/${option.id}`, "_blank")
                      }
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        padding: "10px",
                        "&:hover": {
                          cursor: "pointer",
                        },
                      }}
                    >
                      <SearchIcon />
                      <Typography>{option.name.substr(0, 100)}</Typography>
                    </ListItem>
                  );
                }
              })}
            </List>
          )}
        </Box>
        {/* <------------iconsOr login-----------> */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "16px",
            height: "fit-content",
          }}
        >
          <Box
            sx={{
              position: "relative",
              width: "fit-content",
              height: "fit-content",
            }}
          >
            <FavoriteBorderIcon
              sx={{ width: "25px", height: "25px", position: "relative" }}
            />
            <Box
              sx={{
                borderRadius: "100%",
                position: "absolute",
                left: "60%",
                top: "-10%",
                backgroundColor: "secondary.main",
                padding: "0px 4px",
                height: "fit-content",
                margin: "0px",
              }}
            >
              <Box
                sx={{
                  margin: "0px",
                  padding: "0px",
                  color: "primary.contrastText",
                  fontSize: "12px",
                  fontWeight: "400",
                }}
              >
                0
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              position: "relative",
              width: "fit-content",
              height: "fit-content",
            }}
          >
            <LocalMallOutlinedIcon sx={{ width: "25px", height: "25px" }} />
            <Box
              sx={{
                borderRadius: "100%",
                position: "absolute",
                left: "60%",
                top: "-10%",
                backgroundColor: "secondary.main",
                padding: "0px 4px",
                height: "fit-content",
                margin: "0px",
              }}
            >
              <Box
                sx={{
                  margin: "0px",
                  padding: "0px",
                  color: "primary.contrastText",
                  fontSize: "12px",
                  fontWeight: "400",
                }}
              >
                0
              </Box>
            </Box>
          </Box>
          <Button
            variant="outlined"
            sx={{
              color: "secondary.main",
              border: "1px solid",
              borderColor: "secondary.main",
              borderRadius: "5px",
            }}
            href="/registerseller"
          >
            Become a seller
          </Button>
          {user ? (
           <Box>
           <Box
             display="flex"
             alignItems="center"
             justifyContent="flex-end"
             gap={1}
             onClick={handleClick}
             sx={{ cursor: 'pointer' }}
           >
             <Avatar
               src={user?.image}
               alt={`profile-${user?.firstName}`}
               sx={{ width: 60, height: 60 }}
             />
             <Box
               display="flex"
               flexDirection="column"
               alignItems="center"
               justifyContent="center"
             >
               <Typography variant="body2" fontWeight="bold" color="primary">
                 {user.firstName}
               </Typography>
               <Typography variant="body2" color="black">
                 {user.accountType}
               </Typography>
             </Box>
           </Box>
           <Menu
             anchorEl={anchorEl}
             open={openmenu}
             onClose={handleClose}
             transformOrigin={{ horizontal: 'right', vertical: 'top' }}
             anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
           >
             <MenuItem onClick={handleClose}>
               <ListItemText>{user.name}</ListItemText>
             </MenuItem>
             <MenuItem onClick={handleClose}>
               <ListItemText>{user.accountType}</ListItemText>
             </MenuItem>
            <MenuItem onClick={handleLogout}>
               <ListItemIcon>
                 <Logout fontSize="small" />
               </ListItemIcon>
               <ListItemText>Logout</ListItemText>
             </MenuItem>
           </Menu>
         </Box>
          ) : (
            <>
              <Button
                variant="contained"
                href="/login"
                sx={{
                  color: "secondary.contrastText",
                  backgroundColor: "secondary.main",
                  border: "none",
                  borderRadius: "5px",
                }}
              >
                Login
              </Button>
            </>
          )}
        </Box>
      </Box>
      {/* <--------mobile header-----> */}
      <MobileHeader />
    </Box>
  );
};

export default Header;