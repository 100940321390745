import { Box, Button, CircularProgress, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import RedeemIcon from "@mui/icons-material/Redeem";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Link, useNavigate, useParams } from "react-router-dom";
import { get } from "../services";
import { toast } from "react-toastify";
import { IN_PRODUCT, PROD_IMG_FUNC } from "../BaseUrl";


{/*const data = [
  "men",
  "women",
  "winter",
  "Home",
  "Kitchen",
  "Office",
  "Decorative",
  "men",
  "women",
  "winter",
  "Home",
  "Kitchen",
  "Office",
];*/}

const MainCategory = () => {
  const {id}=useParams();

  const [productDetails,setProductDetails]=useState(null);
  const [selectedVariant,setSelectedVariant]=useState(null);
  const [image,setImage]=useState(null);
  const [imgPath,setImgPath]=useState(null);
  const [similarProducts,setSimilarProducts]=useState(null);
  const [postalCode,setPostalCode]=useState("");
  const [eddContent,setEddContent]=useState("");
  const [loading,setLoading]=useState(false);
  const navigate=useNavigate();

  //const handleAddToCart = () => {
    //navigate('src/components/Cart/Cart.jsx'); // Adjust the path based on your routing structure
  //};
  

  useEffect(()=>{

    if(id){
      get(IN_PRODUCT+'/get-product-details/'+id).then((res)=>{
        const {data}=res;
        // console.log(data);
        setProductDetails(data);
        setSelectedVariant(data.ProductVariants[0]);
        const imageUrl=PROD_IMG_FUNC(data.User.secure_id);
        setImgPath(imageUrl);
        setImage(imageUrl+data.thumbnail_image_url);
      }).catch((e)=>{
        toast.error("Product is not found");
      });


    }

  },[]);

  const formatDate = (dateString) => {
    const [day, month, year] = dateString.split("-");
    const formattedDate = new Date(`${year}-${month}-${day}`); // Parse into Date object
    const options = { day: "numeric", month: "short", weekday: "long" };
    const dateStringFormatted = formattedDate.toLocaleDateString("en-US", options);
    const [weekday, dayMonth] = dateStringFormatted.split(", "); // Separate weekday and day-month
    return `Delivery by ${dayMonth}, ${weekday}`;
  };

  const handleCheckEdd=()=>{
    setLoading(true);
    if(postalCode.length!==6){
      setEddContent('Please enter a valid pin code');
      setLoading(false);
      return;
    }
    const params={
      id:productDetails.id,
      code:postalCode,
    }
    get(IN_PRODUCT+'get-edd',{params}).then((res)=>{
      const {data}=res;
      setEddContent(formatDate(data));
      setLoading(false);
    }).catch((e)=>{
      console.log(e);
      setLoading(false);
    })
  }

  useEffect(()=>{
    if(productDetails){
      const params={
        'category':productDetails?.Item?.ItemCategory?.id,
        'limit':10,
      }
      get(IN_PRODUCT+'/get-similar-products',{params}).then((res)=>{
        const {data}=res;
        setSimilarProducts(data.data);
      }).catch((e)=>{
        console.log(e);
      });
    }
  },[productDetails]);

  const round=(num)=>{
    return Math.round(num*10)/10;
  }

  return (
    <Box>
      <Box
        sx={{
          padding: { md: "16px 73px", xs: "19px 16px" },
          "&::-webkit-scrollbar": {
            display: "none",
          },

          "-ms-overflow-style": "none",
          "scrollbar-width": "none",
          overflow: { md: "hidden", xs: "scroll" },
          backgroundColor: "#455F76",
          display: "flex",
          alignItems: "center",
          gap: { md: "48px", xs: "28px" },
        }}
      >
        {/*data.map((data) => (
          <Box
            sx={{
              fontSize: { md: "16px", xs: "12px" },
              fontFamily: "Montserrat",
              fontWeight: { md: "600", xs: "500" },
              color: "white",
            }}
          >
            {data}
          </Box>
        ))*/}
      </Box>
      <Box sx={{ padding: { md: "20px 56px" }, width: "100%" }}>
        <Box
          sx={{
            padding: { md: "20px" },
            width: "100%",
            boxShadow: `0px 7.39px 14.78px -2.46px #919EAB1F, 0px 0px 1.23px 0px #919EAB33`,
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: { md: "start", xs: "center" },
              gap: "24px",
              flexDirection: { md: "row", xs: "column" },
            }}
          >
            <Box
              sx={{
             
                // width: "100%",
                gap: "25px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  padding: { md: "40px", xs: "17px 12px" },
                  border: "1px solid #BABABA",
                  width: "fit-content",
                }}
              >
                <Box component="img" sx={{ objectFit: "cover" ,width:"380px"}} src={image} />
              </Box>
              <Box
              sx={{
                display: { md: "flex", xs: "none" },
                gap: "12px",
                overflowX: "auto", // Enable horizontal scrolling
                // scrollbarWidth: "thin", // For custom scrollbar styling (works in Firefox)
                "&::-webkit-scrollbar": {
                  height: "8px", // Set scrollbar height for WebKit browsers
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#BABABA", // Customize scrollbar thumb
                  borderRadius: "4px",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#f0f0f0", // Customize scrollbar track
                },
                width:"455px"
              }}
            >
              {productDetails?.ProductImages?.map((img) => {
                if (!(img?.image_url?.includes("mp4"))) {
                  return (
                    <Box
                      sx={{
                        padding: { md: "5px 7px", xs: "" },
                        border: "1px solid #BABABA",
                        width: "fit-content",
                        "&:hover": {
                          cursor: "pointer",
                        },
                        mb:1,
                      }}
                      onClick={(e) => setImage(imgPath + img?.image_url)}
                      key={img.image_url} // Add a key for better React rendering performance
                    >
                      <Box
                        component="img"
                        sx={{ objectFit: "cover", width: "84px" }}
                        src={imgPath + img?.image_url}
                      />
                    </Box>
                  );
                }
                return null;
              })}
            </Box>

              <Box
                sx={{
                  gap: "13px",
                  width: "100%",
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                }}
              >
                {/*<Button
                  sx={{
                    padding: "20px 28px",
                    color: "white",
                    fontWeight: "700",
                    fontSize: "20px",
                    lineHeight: "24px",
                    backgroundColor: "#FF944E",
                    borderRadius: "0px",
                  }}
                >
                  <LocalGroceryStoreIcon />
                  <Typography onClick={handleAddToCart} style={{ cursor: 'pointer' }}> Add To Cart</Typography>
                </Button>*/}
                
                <Button
                  sx={{
                    padding: "20px 28px",
                    color: "white",
                    fontWeight: "700",
                    fontSize: "20px",
                    lineHeight: "24px",
                    backgroundColor: "#8CB89F",
                    borderRadius: "0px",
                    '&:hover':{
                      cursor:'pointer'
                    }
                  }}
                  onClick={(e)=>navigate(`/checkout?id=${productDetails?.id}&v=${selectedVariant?.id}`)}
                >
                  <FlashOnIcon />
                  <Typography>Buy Now</Typography>
                </Button>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: { md: "", xs: "17px 12px" },
                width: "100%",
                gap: "16px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      color: "#A1A1A1",
                    }}
                  >
                    {/* {`Home > mobile & accessories > mobiles > samsung >`} */}
                    <Typography
                      sx={{
                        fontWeight: "400",
                        fontFamily: "Montserrat",
                        fontSize: "16px",
                        color: "#455F76",
                      }}
                      component="span"
                    >

                      {productDetails?.Item?.item_name?.toUpperCase()}
                    </Typography>
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "600",
                      fontFamily: "Montserrat",
                      fontSize: "16px",
                      color: "#455F76",
                    }}
                  >
                    Share
                  </Typography>
                </Box>
              </Box>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontFamily: "Montserrat",
                  fontSize: "28px",
                }}
              >
               {productDetails?.product_name}
              </Typography>
              {/* <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <StarIcon
                  sx={{ width: "36px", height: "36px", color: "#F2C210" }}
                />
                <StarIcon
                  sx={{ width: "36px", height: "36px", color: "#F2C210" }}
                />
                <StarIcon
                  sx={{ width: "36px", height: "36px", color: "#F2C210" }}
                />
                <StarBorderIcon
                  sx={{ width: "36px", height: "36px", color: "#A1A1A1" }}
                />
                <Typography>10 Reviews</Typography>
              </Box> */}
              <Box sx={{ display: "flex", gap: "5px", alignItems: "flex-end" }}>
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "36px",
                  }}
                >
                  ₹ {selectedVariant?.iz_price}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "20px",
                    color: "#ff944e",
                  }}
                >
                  {productDetails && round((productDetails?.tag_price-selectedVariant?.iz_price)*100/productDetails?.tag_price)}% off
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "5px", color: "#BABABA" }}>
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                  }}
                >
                  MRP:
                  <s
                    style={{
                      fontWeight: "500",
                      fontFamily: "Montserrat",
                      fontSize: "16px",
                      textDecoration: "Strikethrough",
                    }}
                  >
                    ₹ {productDetails?.tag_price}
                  </s>
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontWeight: "500",
                  fontFamily: "Montserrat",
                  fontSize: "16px",
                }}
              >
                Inclusive of all taxes
              </Typography>
             
              {/* <Typography
                sx={{
                  fontWeight: "500",
                  fontFamily: "Montserrat",
                  fontSize: "16px",
                }}
              >
                EMI starts at ₹ 923. No cost EMI Options
              </Typography> */}
              {/* <Typography
                sx={{
                  fontWeight: "600",
                  fontFamily: "Montserrat",
                  fontSize: "16px",
                  color: "#a1a1a1",
                }}
              >
                Color:
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
                <Button
                  variant="outlined"
                  sx={{
                    color: "#C2544C",
                    fontSize: "16px",
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                  }}
                >
                  Red
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    color: "black",
                    fontSize: "16px",
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                  }}
                >
                  Black
                </Button>
              </Box> */}
              {/* <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  fontFamily: "Montserrat",
                  color: "#455F76",
                }}
              >
                Available Color: 2
              </Typography> */}
              {/* <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  fontFamily: "Montserrat",
                  color: "#A1A1A1",
                }}
              >
                Eligible offers:
              </Typography> */}

              {/* <Box sx={{ display: "flex", gap: "10px" }}>
                <Box
                  sx={{
                    width: "284px",
                    padding: "10px",
                    borderRadius: "10px",
                    border: "1px solid #BABABA",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "start", gap: "12px" }}
                  >
                    <RedeemIcon />
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontFamily: "Montserrat",
                        fontWeight: "500",
                        lineHeight: "24px",
                      }}
                    >
                      Get 10% off on Minimum order value Rs 1499
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      padding: "7px",
                      gap: "12px",
                      borderRadius: "10px",
                      justifyContent: "center",
                      border: "1px dashed #e1e1e1",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        lineHeight: "17.07px",
                        fontWeight: "500",
                        fontSize: "14px",
                        color: "#f89b43",
                      }}
                    >
                      NEW YEAR SAVE 10
                    </Typography>
                    <ContentCopyIcon sx={{ width: "15px", color: "#f89b43" }} />
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "284px",
                    padding: "10px",
                    borderRadius: "10px",
                    border: "1px solid #BABABA",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "start", gap: "12px" }}
                  >
                    <RedeemIcon />
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontFamily: "Montserrat",
                        fontWeight: "500",
                        lineHeight: "24px",
                      }}
                    >
                      Get 10% off on Minimum order value Rs 1499
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      padding: "7px",
                      gap: "12px",
                      borderRadius: "10px",
                      justifyContent: "center",
                      border: "1px dashed #e1e1e1",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        lineHeight: "17.07px",
                        fontWeight: "500",
                        fontSize: "14px",
                        color: "#f89b43",
                      }}
                    >
                      NEW YEAR SAVE 10
                    </Typography>
                    <ContentCopyIcon sx={{ width: "15px", color: "#f89b43" }} />
                  </Box>
                </Box>
              </Box> */}

             {/*<Box sx={{ display: "flex", gap: "4px" }}>
                <LocalOfferIcon sx={{ color: "#8CB89F" }} />
                <Typography>
                  Bank Offer 5% Cashback on Axis Bank Credit Card
                  <Link>T&C</Link>
                </Typography>
              </Box> */}

              {/*<Box sx={{ display: "flex", gap: "4px" }}>
                <LocalOfferIcon sx={{ color: "#8CB89F" }} />
                <Typography>
                  Bank Offer 15% Cashback on EMI purchase with any Bank Credit
                  Card
                  <Link>T&C</Link>
                </Typography>
              </Box>*/}
              
              <Typography
                sx={{ color: "#a1a1a1", fontSize: "16px", fontWeight: "600" }}
              >
                Ship to
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "fit-content",
                  height: "32px",
                  position: "relative",
                }}
              >
                <TextField
                  placeholder="Write pin code here to check"
                  type="number"
                  InputProps={{
                    inputProps: {
                      style: {
                        appearance: "textfield", // Remove spinner arrows in most browsers
                      },
                    },
                    sx: {
                      "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
                        appearance: "none", // Remove spinner arrows in WebKit browsers
                        margin: 0,
                      },
                    },
                  }}
                  sx={{
                    width: {
                      xs: "100%", // Full width on extra-small screens
                      sm: "75%", // 75% width on small screens
                      md: "562px", // Fixed width on medium and larger screens
                    },
                    height: "32px", // Set height to 32px to match the button
                    padding: 0, // Remove any default padding
                    "& .MuiInputBase-root": {
                      height: "32px", // Ensure the input field root matches the height
                    },
                    "& .MuiInputBase-input": {
                      padding: "6px", // Adjust padding for inner input text
                    },
                    "& .MuiAutocomplete-listbox": {
                      "&::-webkit-scrollbar": {
                        display: "none",
                      },
                      scrollbarWidth: "none",
                      "-ms-overflow-style": "none",
                    },
                  }}
                  value={postalCode}
                  onChange={(e)=>setPostalCode(e.target.value)}

                />
                <Button
                  sx={{
                    position: "absolute",
                    right: "0%",
                    width: "32px",
                    height: "32px", // Match the TextField height
                    borderRadius: "5px",
                    color: "white",
                    backgroundColor: "primary.main",
                    padding: "6px",
                    "&:hover": {
                      backgroundColor: "primary.dark", // Optional: change color on hover
                    },
                  }}

                  onClick={handleCheckEdd}
                >
                  {loading?<CircularProgress size={20} sx={{color:"white"}}/>:"Search"}
                </Button>
              </Box>
              <Typography
                sx={{ color: "#5B5B5B", fontSize: "14px", fontWeight: "400" }}
              >
                Please enter PIN code to check delivery time & Cash on Delivery
                Availability
              </Typography>
              <Typography
                sx={{ color: "tertiary.main", fontSize: "14px", fontWeight: "600" }}
              >
                {eddContent}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "start",
                  gap: "24px",
                  flexDirection: "column",
                  padding: "24px 16px",
                  borderRadius: "5px",
                  border: "1.05px solid #d9d9d9",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#A1A1A1",
                    lineHeight: "20px",
                  }}
                >
                  Sold By:{" "}
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "black",
                      fontWeight: "700",
                      lineHeight: "20px",
                    }}
                    component="span"
                  >
                    {productDetails?.User?.businessDetails?.shop_name}
                  </Typography>
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "black",
                    fontWeight: "700",
                    lineHeight: "20px",
                  }}
                  component="span"
                >
                  Product Description:
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "22px",
                    color: "#6B6B6B",
                  }}
                >
                  {productDetails?.product_description}
                </Typography>
                <Box sx={{ display: "flex", gap: "2px", alignItems: "center" }}>
                  <Typography
                    sx={{
                      fontWeight: "700",
                      color: "#455F76",
                      fontSize: "14px",
                      lineHeight: "17.07px",
                    }}
                  >
                    See More
                  </Typography>
                  <KeyboardArrowDownIcon />
                </Box>
              </Box>

             {/* <Box
                sx={{
                  display: "flex",
                  alignItems: "start",
                  gap: "24px",
                  flexDirection: "column",
                  padding: "24px 16px",
                  borderRadius: "5px",
                  border: "1.05px solid #d9d9d9",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "black",
                    fontWeight: "700",
                    lineHeight: "20px",
                  }}
                  component="span"
                >
                  Product Specification
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "16px",
                    width: "100%",
                    flexDirection: "column",
                  }}
                >
                  {productDetails?.OtherProductAttributes?.map((data) => (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#94969f",
                        }}
                      >
                        
                        {data.Attribute.name}
                      </Typography>
                      <Typography sx={{ fontSize: "12px", fontWeight: "700" }}>
                      {data.value}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                <Box sx={{ display: "flex", gap: "2px", alignItems: "center" }}>
                  <Typography
                    sx={{
                      fontWeight: "700",
                      color: "#455F76",
                      fontSize: "14px",
                      lineHeight: "17.07px",
                    }}
                  >
                    See More
                  </Typography>
                  <KeyboardArrowDownIcon />
                </Box>
              </Box>*/}
              {/* <Typography>Reviews & rating</Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <Box
                  sx={{
                    backgroundColor: "#8CB89F",
                    padding: "6px 7px",
                    display: "flex",
                    alignItems: "center",
                    gap: "5.6px",
                    borderRadius: "5.3px",
                    width: "fit-content",
                  }}
                >
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: "14px",
                      fontWeight: "600",
                      lineHeight: "17.07px",
                    }}
                  >
                    4.2
                  </Typography>
                  <StarIcon sx={{ color: "white", width: "13px" }} />
                </Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "14px",
                    color: "#656565",
                  }}
                >
                  263 ratings | 30 reviews
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  gap: "11.35px",
                  flexWrap: { md: "nowrap", xs: "wrap" },
                }}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8].map((data) => (
                  <Box
                    component="img"
                    sx={{ width: "100px", height: "99px" }}
                    src={phon1}
                  />
                ))}
              </Box>
              <Typography>Customer Reviews (30)</Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "start",
                  gap: "11px",
                  flexDirection: "column",
                  padding: "24px 16px",
                  borderRadius: "5px",
                  border: "1.05px solid #d9d9d9",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "19px",
                  }}
                >
                  Rakhi23
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  {[1, 2, 3, 4, 5].map((data) => (
                    <StarIcon sx={{ width: "20px", color: "#F2C210" }} />
                  ))}
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "24px",
                      color: "#656565",
                    }}
                  >
                    15 Dec 2024
                  </Typography>
                </Box>

                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "18px",
                  }}
                >
                  Great product! Smooth performance and amazing battery life.
                  Highly recommended.
                </Typography>
                <Box
                  sx={{
                    backgroundColor: "#E8EBF6",
                    padding: "8px 10px",
                    borderRadius: "5.3px",
                    width: "fit-content",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "500",
                      lineHeight: "14px",
                    }}
                  >
                    Variant Bought : 8GB RAM red
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "25px" }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "18px",
                      color: "#656565",
                    }}
                  >
                    Is this review helpful?
                  </Typography>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "12px" }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <ThumbUpOffAltIcon sx={{ color: "#455F76" }} />
                      <Typography>8</Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <ThumbDownOffAltIcon sx={{ color: "#FF5252" }} />
                      <Typography>1</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "start",
                  gap: "11px",
                  flexDirection: "column",
                  padding: "24px 16px",
                  borderRadius: "5px",
                  border: "1.05px solid #d9d9d9",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "19px",
                  }}
                >
                  Rakhi23
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  {[1, 2, 3, 4, 5].map((data) => (
                    <StarIcon sx={{ width: "20px", color: "#F2C210" }} />
                  ))}
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "24px",
                      color: "#656565",
                    }}
                  >
                    15 Dec 2024
                  </Typography>
                </Box>

                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "18px",
                  }}
                >
                  Great product! Smooth performance and amazing battery life.
                  Highly recommended.
                </Typography>
                <Box
                  sx={{
                    backgroundColor: "#E8EBF6",
                    padding: "8px 10px",
                    borderRadius: "5.3px",
                    width: "fit-content",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "500",
                      lineHeight: "14px",
                    }}
                  >
                    Variant Bought : 8GB RAM red
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "25px" }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "18px",
                      color: "#656565",
                    }}
                  >
                    Is this review helpful?
                  </Typography>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "12px" }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <ThumbUpOffAltIcon sx={{ color: "#455F76" }} />
                      <Typography>8</Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <ThumbDownOffAltIcon sx={{ color: "#FF5252" }} />
                      <Typography>1</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "start",
                  gap: "11px",
                  flexDirection: "column",
                  padding: "24px 16px",
                  borderRadius: "5px",
                  border: "1.05px solid #d9d9d9",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "19px",
                  }}
                >
                  Rakhi23
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  {[1, 2, 3, 4, 5].map((data) => (
                    <StarIcon sx={{ width: "20px", color: "#F2C210" }} />
                  ))}
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "24px",
                      color: "#656565",
                    }}
                  >
                    15 Dec 2024
                  </Typography>
                </Box>

                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "18px",
                  }}
                >
                  Great product! Smooth performance and amazing battery life.
                  Highly recommended.
                </Typography>
                <Box
                  sx={{
                    backgroundColor: "#E8EBF6",
                    padding: "8px 10px",
                    borderRadius: "5.3px",
                    width: "fit-content",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "500",
                      lineHeight: "14px",
                    }}
                  >
                    Variant Bought : 8GB RAM red
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: "11.35px",
                  }}
                >
                  {[1, 2].map((data) => (
                    <Box
                      component="img"
                      sx={{ width: "100px", height: "99px" }}
                      src={phon1}
                    />
                  ))}
                </Box>

                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "25px" }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "18px",
                      color: "#656565",
                    }}
                  >
                    Is this review helpful?
                  </Typography>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "12px" }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <ThumbUpOffAltIcon sx={{ color: "#455F76" }} />
                      <Typography>8</Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <ThumbDownOffAltIcon sx={{ color: "#FF5252" }} />
                      <Typography>1</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  lineHeight: "19px",
                  color: "#455f76",
                  textDecoration: "Underline",
                }}
              >
                Is this review helpful?
              </Typography> */}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          padding: { md: "20px 56px" },

          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "flex-start",
            justifyContent: "space-between",
            padding: { md: "", xs: "19px 16px" },
          }}
        >
          <Typography
            sx={{
              textAlign: "start",
              fontSize: { md: "28px", xs: "16px" },
              fontWeight: "600",
              fontFamily: "Montserrat",
            }}
          >
            Similar Products
          </Typography>
          <Typography
            sx={{
              color: "primary.main",
              textDecoration: "underline",
              fontSize: { md: "18.58px", xs: "12px" },
              fontWeight: "600",
              fontFamily: "montserrat",
            }}
          >
            see All
          </Typography>
        </Box>

        <Box
          sx={{
            "&::-webkit-scrollbar": {
              display: "none",
            },
            Width: "100%",
            justifyContent: { md: "start", xs: "center" },
            "-ms-overflow-style": "none",
            "scrollbar-width": "none",
            display: "flex",
            gap: { md: "28px", xs: "8px" },
            flexWrap: { md: "nowrap", xs: "wrap" },
            overflow: { md: "scroll", xs: "none" },
          }}
        >
          {similarProducts?.map((data) => (
            <Box
              key={data.product_name}
              sx={{
                padding: { md: "10.47px", xs: "6px" },
                backgroundColor: "customBg.white",
                display: "flex",
                width: { md: "248px", xs: "190px" },
                flexDirection: "column",
                gap: { md: "13px", xs: "6px" },
                borderRadius: { md: "5px", xs: "3px" },
                border: "1px solid",
                borderColor: "primary.light",
                boxShadow: "0px 0px 1.07px 0px #919EAB33",
                '&:hover':{
                  cursor:"pointer",
                }
              }}
              onClick={(e) => window.open(`/product/${data.id}`, '_blank')}
            >
              <Box
                sx={{
                  display: "flex",
                  position: "relative",
                  borderRadius: { md: "5px", xs: "3px" },
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#EAEAEA",
                }}
              >
                {/* <Box
                  sx={{
                    backgroundColor: "primary.contrastText",
                    right: "2%",
                    top: "2%",
                    position: "absolute",
                    padding: { md: "11px 11px", xs: "4px 4px" },
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "100%",
                  }}
                >
                  <Box
                    component="img"
                    src={heart}
                    alt="heart"
                    sx={{
                      width: { md: "20px", xs: "15px" },
                      height: { md: "20px", xs: "15px" },
                      color: "text.secondary",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    backgroundColor: "customBg.white",
                    right: "2%",
                    top: "20%",
                    position: "absolute",
                    padding: { md: "11px 11px", xs: "4px 4px" },
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "100%",
                  }}
                >
                  <Box
                    component="img"
                    src={share}
                    alt="share"
                    sx={{
                      width: { md: "20px", xs: "15px" },
                      height: { md: "20px", xs: "15px" },
                      color: "text.secondary",
                    }}
                  />
                </Box> */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: { md: "245px", xs: "122px" },
                    overflow: "hidden",
                  }}
                >
                  <Box
                    component="img"
                    sx={{
                      objectFit: "cover",
                      height: { md: "245px", xs: "122px" },
                    }}
                    src={PROD_IMG_FUNC(data.User.secure_id) +
                        data.thumbnail_image_url}
                    alt={data.title}
                  />
                </Box>
              </Box>

              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: { md: "19.2px", xs: "14px" },
                  fontFamily: "montserrat",
                  color: "primary.main",
                }}
              >
                {data.product_name.substr(0,50)}
              </Typography>
              {/* <Typography
                sx={{
                  fontSize: { md: "17.45px", xs: "12px" },
                  fontWeight: "500",
                  fontFamily: "montserrat",
                }}
              >
                {data.product_description}...
              </Typography> */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyItems: "start",
                  gap: "5px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: { md: "20px", xs: "12px" },
                    fontWeight: "700",
                    color: "success.main",
                  }}
                >
                  Rs {data.iz_price}
                </Typography>
                <Typography
                  sx={{
                    fontSize: { md: "14px", xs: "8px" },
                    fontWeight: "400",
                    color: "secondary.main",
                    textDecoration: "line-through",
                  }}
                >
                  Rs {data.tag_price}
                </Typography>
              </Box>
              {/* <Box>
                <Typography
                  sx={{
                    fontSize: { md: "12px", xs: "8px" },
                    fontWeight: "500",
                    color: "#455F76",
                  }}
                >
                  Free Delivery
                </Typography>
                <Typography
                  sx={{
                    fontSize: { md: "14px", xs: "8px" },
                    fontWeight: "700",
                    color: "#8CB89F",
                  }}
                >
                  Bank Offer
                </Typography>
              </Box> */}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default MainCategory;
