import React, { useEffect, useState } from 'react'
import { get, post, postFormData } from '../../services'

const Cart = () => {
  const [items, Setitems] = useState("")
  const [order, setOrder] = useState('')
  const [cart, setCart] = useState('')
  const [tax, setTax] = useState('')
  const [taxAmount , setTaxAmount] = useState('')
  const [product , setProduct] = useState('')

useEffect(()=>{
console.log("myCart",cart?.cartItem?.quantity);
  },[cart])

  const myItems = async () => {
    let obj = {
      user_id: 4,
      order_number: "ORD123456",
      order_status: "Delivered",
      payment_status: "Paid",
      payment_mode: "Card",
      discounts_charges_id: 6
    };
    try {
      const item = await postFormData("api/orders/orders", obj);
      Setitems(item) //save items data
      
      console.log("items", item);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  
  const myCartItems = async () => {
    let obj = {
      user_id: 1,
      product_id: 101,
      quantity: 2,
      is_no_return_discount: 3,
      product_variant_id :1,
      status: "Pending"
    };
    try {
      const item = await postFormData("api/cart-items/cart-item", obj);
      setCart(item) //save items data
      
      console.log("myCartItems", item);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const myTax = async () => {
    let obj = {
      order_id: 1,
      product_id: 1
    };
  
    try {
      const queryString = new URLSearchParams(obj).toString();
      
      const res = await get(`api/taxes/tax-amounts?${queryString}`);
      
      setTax(res); // Save items data
      
      console.log("myCartItems", res);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const invoices = async () => {
    try {
      // Assuming `obj` contains an ID or value you want to append
      const id = 2; // Replace 2 with the default ID if `obj.id` is not available
      
      const res = await get(`api/invoices/product-order-invoices/${id}`);
      
      setTax(res); // Save items data
      
      console.log("myCartItems", res);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  
  
  const addresses = async () => {
    let obj = 1
  
    try {
      const queryString = new URLSearchParams(obj).toString();
      
      const res = await get(`api/userAddress/user-addresses?${obj}`);
      
      setTax(res); // Save items data
      
      console.log("myCartItems", res);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  
  const myTaxAmount = async () => {
    let obj = {
      order_id: 1,
      product_id: 1,
      cgst_amount: 50.00,
      sgst_amount: 50.00,
      igst_amount: 0.00,
      transaction_type: "Intra",
      tax_amount_id: 1
    };
    try {
      const taxAmount = await postFormData("api/taxes/tax-amount", obj);
      setTaxAmount(taxAmount) 
      
      console.log("myTaxAmount", taxAmount);
    } catch (error) {
      console.error("Error:", error);
    }
  };


  // const myProduct = async () => {
  //   let obj = {
  //     user_id: 10,
  //     item_id: 202,
  //     product_name: "iPhone 14",
  //     brand_id: 301,
  //     packed_weight: 0.5,
  //     price: 999.99,
  //     description: "Apple iPhone 14 with 128GB storage, sleek design, and high performance."
  //   };
  //   try {
  //     const product = await postFormData("api/products/product", obj);
  //     setProduct(product) 
      
  //     console.log("product", product);
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };
//   const discounts = async () => {
//     let obj = {
//       order_id: 1,
// product_id: 4,
// coupon_discount: 10.50,
// bank_discount: 5.00,
// no_return_discount: 0.00,
// cod_charges: 2.00,
// shipping_charges: 3.50,
// packing_charges: 1.00,
// handling_charges: 0.50,
// gross_amount: 65.00

//     };
//     try {
//       const product = await postFormData("api/discounts/discount-charge", obj);
//       setProduct(product) 
      
//       console.log("product", product);
//     } catch (error) {
//       console.error("Error:", error);
//     }
//   };




  // const user = async () => {
  //  let userId = 1;
  //   try {
      
  //     const res = await get(`api/users/users/${userId}`);
      
  //     setTax(res); // Save items data
      
  //     console.log("myCartItems", res);
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };



 useEffect(() =>{
myItems()
myCartItems()
myTax()
myTaxAmount()
// myProduct()
addresses()
invoices()
// discounts()

 },[])

  const [cartItems, setCartItems] = useState([
    {
      id: 1,
      name: "ONE MINUS",
      description: "Full cinematic primary camera stable good...",
      price: 31120.23,
      originalPrice: 40220.23,
      quantity: 2,
      deliveryDate: "Jan 14, Tue",
      image: "/assets/images/image 159 (1).png",
    },
    {
      id: 2,
      name: "CAM CUNG",
      description: "Galaxy A76 good cinematic primary camera with high...",
      price: 21985.23,
      originalPrice: 32220.23,
      quantity: 1,
      deliveryDate: "Jan 14, Tue",
      image: "/assets/images/image 162.svg",
    },
  ]);

  // Function to handle adding an item
  const addItem = (item) => {
    const existingItem = cartItems.find((cartItem) => cartItem.id === item.id);
    if (existingItem) {
      // If item exists, increase its quantity
      setCartItems(
        cartItems.map((cartItem) =>
          cartItem.id === item.id
            ? { ...cartItem, quantity: cartItem.quantity + 1 }
            : cartItem
        )
      );
    } else {
      // If item does not exist, add it to the cart
      setCartItems([...cartItems, { ...item, quantity: 1 }]);
    }
  };

  // Function to handle deleting an item
  const deleteItem = (id) => {
    setCartItems(cartItems.filter((item) => item.id !== id));
  };

  // Function to update quantity
  const updateQuantity = (id, amount) => {
    setCartItems(
      cartItems.map((item) =>
        item.id === id
          ? { ...item, quantity: Math.max(1, item.quantity + amount) } // Ensure quantity doesn't drop below 1
          : item
      )
    );
  };

  // Calculate total price
  const calculateTotal = () => {
    return cartItems.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2);
  };
  return (
    <>
     <div className=''>

    {/* Cart Section */}
    <div className='add-sec-Cart'>
    <h3 className="">Your Cart</h3>
    </div>

    <div className="e-cart-card">
    <div className="row add-item-sec">
      {/* Left Column */}
      <div className="col-md-8 add-itms">
        {cartItems.map((item) => (
          <div className="card mb-3" key={item.id}>
            <div className="card-body d-flex">
              <img
                src={item.image}
                alt={item.name}
                style={{ width: "100px" }}
                className="me-3"
              />
              <div className="flex-grow-1">
                <h5 className="card-title">{item.name}</h5>
                <p className="card-text text-muted">{item.description}</p>
                <div className="d-flex justify-content-between">
                  <div>
                    <span className="text-success">₹{item.price.toFixed(2)} / unit</span>
                    <span className="text-muted ms-2">
                      <s>₹{item.originalPrice.toFixed(2)}</s>
                    </span>
                  </div>
                  
                </div>
                <small className="text-muted">Delivery by {item.deliveryDate}</small>
              </div>
              <div className="d-flex flex-column align-items-center justify-content-between">
              <div className="d-flex align-items-center gap-2 remove-web-view ">
              <small className="fs-6" style={{color:"#FF944E"}}>
                {/* <BiCartDownload/> */}
                Buy only this
                  </small>
             
                <div
                  className=" btn-sm text-danger fs-6"
                  onClick={() => deleteItem(item.id)}
                >
                  Remove
                </div>
                </div>
              </div>
              
            </div>
            <div className='item-count-div'>
            <div className="mt-3 add-product-item">
                  <div
                    className="btn-sm"
                    onClick={() => updateQuantity(item.id, -1)}
                  >
                    -
                  </div>
                  <span className="mx-2">{item.quantity}</span>
                  <div
                    className="btn-sm"
                    onClick={() => updateQuantity(item.id, 1)}
                  >
                    +
                  </div>
                </div>

            </div>

              <div className=" gap-2 remove-mobile-view ">
              <small className="fs-6" style={{color:"#FF944E"}}>
                {/* <BiCartDownload/> */}
                Buy only this
                  </small>
                  <span className='line'></span>
                <div
                  className=" btn-sm text-danger fs-6"
                  onClick={() => deleteItem(item.id)}
                >
                  Remove
                </div>
                </div>
              </div>
     
        ))}
      </div>

      {/* Right Column */}
      <div className="col-md-4">
        <div className="card right-price-sec">
          <div className="card-body">
            <h5 className="card-title">Order Summary</h5>
            <div className='price-sec'>
            <div className="d-flex justify-content-between">
              <span>Price</span>
              <span>₹{calculateTotal()}</span>
            </div>
            <div className="d-flex justify-content-between">
              <span>Discount</span>
              <span className="text-success">₹0.00</span>
            </div>
            <div className="d-flex justify-content-between">
              <span>Delivery</span>
              <span className="text-success">FREE</span>
            </div>
            <div className="d-flex justify-content-between">
              <span>Taxes</span>
              <span>₹149.54</span>
            </div>
            <hr />
            <div className="d-flex justify-content-between">
              <strong>Total</strong>
              <strong>₹{(parseFloat(calculateTotal()) + 149.54).toFixed(2)}</strong>
            </div>
            </div>
           
           
          </div>
        </div>
        <button className="btn w-100 mt-3 fs-5" style={{background:"#FF944E", color:'#fff'}}>Checkout</button>
      </div>
    </div>
  </div>
  </div>

    </>
   
  )
}

export default Cart