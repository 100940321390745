import { Box, IconButton, Typography,LinearProgress, Grid  } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import man from "../../assets/trend/man.png";
import man2 from "../../assets/trend/man2.png";
import brass from "../../assets/trend/brash.png";
import pent from "../../assets/trend/pent.png";
import krishna from "../../assets/trend/krishna.png";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import share from "../../assets/trend/Vector.svg";
import heart from "../../assets/trend/Vector (1).svg";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { get } from "../../services";
import { IN_PRODUCT, IN_URL, PROD_IMG_FUNC } from "../../BaseUrl";
import { PlayArrow, Pause, Favorite, Share } from "@mui/icons-material";
import SingleVideo from "./SingleVideo";

const data = [
  {
    name: "Stylish Jeans free size, fast fashion hub....",
    views: "345 views",
    img: man2,
  },
  {
    name: "Stylish Jeans free size, fast fashion hub....",
    views: "345 views",
    img: man2,
  },
  {
    name: "Stylish Jeans free size, fast fashion hub....",
    views: "345 views",
    img: man2,
  },
  {
    name: "Stylish Hat brown foe mens, gen Z styl...",
    views: "45 views",
    img: pent,
  },
  {
    name: "Cross designs shirts Blue green trending...",
    views: "1.3k views",
    img: man,
  },
  {
    name: "Brass finish dolls with traditional cultural...",
    views: "15 views",
    img: brass,
  },
  {
    name: "Krishna ji Vrindavan painting in full ....",
    views: "3.56k views",
    img: krishna,
  },
];



const Trend = () => {
  const [videos,setVideos]=useState([]);
  const [randomVideos, setRandomVideos] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0); // To handle the current video
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const videoRef = useRef(null);

  useEffect(()=>{
    const params={
      limit:15
    }
    get(IN_URL+'videos',{params}).then((res)=>{
      const {data}=res;
       // Shuffle videos and pick 3 unique random videos
       const shuffledVideos = [...data.data].sort(() => 0.5 - Math.random());
       setRandomVideos(shuffledVideos.slice(0, 15));
      setVideos(data.data);
    }).catch((e)=>{

    })
  },[]);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      const updateProgress = () => {
        setProgress((video.currentTime / video.duration) * 100);
      };
      video.addEventListener("timeupdate", updateProgress);
      return () => video.removeEventListener("timeupdate", updateProgress);
    }
  }, [currentIndex]);

  if (videos.length === 0) {
    return <Box>Loading videos...</Box>;
  }

  const handlePlayPause = () => {
    if (videoRef.current) {
      const isVideoPlaying = !videoRef.current.paused && !videoRef.current.ended && videoRef.current.readyState > 2;
  
      if (isVideoPlaying) {
        videoRef.current.pause();
        setIsPlaying(false);
      } else {
        videoRef.current.play().catch((error) => {
          console.error("Error playing video:", error);
        });
        setIsPlaying(true);
      }
    }
  };


  return (
    <Box
      sx={{
        backgroundColor: "#E9FFF0",
        padding: { md: "40px 56px", xs: "24px 16px" },
        display: "flex",
        alignItems: "start",
        gap: { md: "14px", xs: "20px" },
        width: "100%",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: { md: "13px", xs: "7px" },
            alignItems: "center",
          }}
        >
          <PlayCircleIcon
            sx={{ color: "secondary.main", width: "33px", height: "33px" }}
          />
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: { md: "28px", xs: "16px" },
              lineHeight: "28px",
              fontFamily: "Montserrat",
            }}
          >
            Trend's
          </Typography>
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: { md: "16px", xs: "14px" },
              lineHeight: "28px",
              fontFamily: "Montserrat",
              color: "success.main",
            }}
          >
            (Swipe & Buy)
          </Typography>
        </Box>
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontSize: { md: "18.58px", xs: "12px" },
            fontWeight: "600",
            textDecoration: "underline",
            color: "primary.main",
          }}
        >
          See All
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          // Hide scrollbar for IE, Edge, and Firefox
          "-ms-overflow-style": "none", // IE and Edge
          "scrollbar-width": "none", // Firefox
          overflow: "scroll",
          gap: { md: "20px", xs: "14px" },
        }}
      >
        {videos.length>0 && videos.map((vid) => (
          <Box
            sx={{
              width: "100%",
              gap: { md: "16px", xs: "11px" },
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                width: { md: "246px", xs: "155px" },
                display: "flex",
                position: "relative",
                height: { md: "468px", xs: "339px" },
                borderRadius: "10px",
                overflow: "hidden",
                alignItems: "center",
                justifyContent: "center",
                // "&:hover .overlay": {opacity: 1 ,visibility: "visible",},
                // '&:hover':{
                //   cursor:"pointer",
                // }
              }}
            >
              {/* <Box
                sx={{ objectFit: "cover", width: "100%", height: "100%" }}
                src={data.img}
                component="img"

              /> */}
                
              <SingleVideo vid={vid} />
              <Box
              className="overlay"
                sx={{
                  position: "absolute",
                  top: 0,
                  zIndex: "4",
                  left: 0,
                  width: "100%",
                  height: "100%",
                  opacity: 0, // Initially hidden
                  visibility: "hidden", // Makes it non-interactive
                  backgroundColor: "#0000004D", // Semi-transparent overlay
                  transition: "opacity 0.2s ease-in-out, visibility 1s ease-in-out",
                }}
              >
              <Box
                sx={{
                  backgroundColor: "primary.contrastText",
                  right: "4%",
                  top: "4%",
                  zIndex: "5",
                  position: "absolute",
                  padding: { md: "11px 11px", xs: "4px 4px" },
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "100%",
                  '&:hover':{
                    cursor:"pointer"
                  }
                }}
              >
                <Box
                  component="img"
                  src={heart}
                  alt="heart"
                  sx={{
                    width: { md: "27px", xs: "15px" },
                    height: { md: "27px", xs: "15px" },
                    color: "text.secondary",
                  }}
                />
              </Box>
              <Box
                sx={{
                  backgroundColor: "customBg.white",
                  right: "4%",
                  zIndex: "5",
                  top: "18%",
                  position: "absolute",
                  padding: { md: "11px 11px", xs: "4px 4px" },
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "100%",
                }}
              >
                <Box
                  component="img"
                  src={share}
                  alt="share"
                  sx={{
                    width: { md: "27px", xs: "15px" },
                    height: { md: "27px", xs: "15px" },
                    color: "text.secondary",
                  }}
                />
              </Box>
              <PlayArrowIcon
                sx={{
                  color: "customBg.white",
                  position: "absolute",
                  top: "48%",
                  left: "48%",
                  width: "37px",
                  height: "37px",
                  zIndex: "5",
                }}
              />
              </Box>
            
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: { md: "8px", xs: "3px" },
                width: { md: "246px", xs: "155px" },
              }}
            >
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: { md: "20px", xs: "14px" },
                  lineHeight: { md: "28px", xs: "14px" },
                  fontFamily: "Montserrat",
                  width: { md: "230px", xs: "155px" },
                  wordBreak: "break-word", // Ensures text wraps at word boundaries
                  overflowWrap: "break-word", // Ensures long words are wrapped
                }}
              >
                {vid.title}
              </Typography>
              <MoreVertIcon
                sx={{
                  width: { md: "20px", xs: "14px" },
                  height: { md: "28px", xs: "20px" },
                  color: "primary.main",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                gap: { md: "7px", xs: "5px" },
              }}
            >
              <RemoveRedEyeOutlinedIcon
                sx={{
                  width: { md: "23px", xs: "16px" },
                  height: { md: "23px", xs: "16px" },
                }}
              />
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: { md: "16px", xs: "12px" },
                  lineHeight: "24px",
                  fontFamily: "Montserrat",
                }}
              >
                {/* {vid.views} */}
                {Math.floor(Math.random() * (800 - 10 + 1)) + 10}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};




// const Trend = () => {
//   const videoUrls = [
//     "video1.mp4",
//     "video2.mp4",
//     "video3.mp4",
//     // Add more video URLs here
//   ];
//   const [videos,setVideos]=useState([]);
//   useEffect(()=>{
//         const params={
//           limit:15
//         }
//         get(IN_URL+'videos',{params}).then((res)=>{
//           const {data}=res;
//           setVideos(data.data);
//         }).catch((e)=>{
    
//         })
//       },[]);
    

//   return (
//     <Grid container spacing={2} sx={{ p: 2 }}>
//       {videos.map((vid, index) => (
//         <Grid item xs={12} sm={6} md={4} key={index}>
//           <SingleVideo vid={vid} />
//         </Grid>
//       ))}
//     </Grid>
//   );
// };

export default Trend;
