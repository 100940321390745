import React from 'react'
import trend from '../../assets/dukan/Group 1000005211.png';
import { Box } from '@mui/system';
//import line from "../../assets/dukan/line.png"
import red from '../../assets/dukan/red.png'
import green from '../../assets/dukan/green.png'
import { Typography } from '@mui/material';
import dukan from '../../assets/subHeader/bhartdukan.gif'
const ShowEverything = () => {
  return (
    <Box sx={{padding:{md:"38px 282px 0px 47px",xs:"64px 15px 0px 15px"}}}>

    <Box sx={{display:'flex',flexDirection:{md:'row',xs:"column-reverse"},alignItems:{md:'end',xs:'center'},gap:{md:'96px',xs:'26px'},position:'relative'}}>
     {/*<Box component='img' src={line} sx={{width:'235',display:{md:'block',xs:'none'},height:'15px',left:'65%',bottom:'30%',position:'absolute'}}/> */}
      <Box component='img' src={dukan} sx={{width:'102px',display:{md:'block',xs:'none'},height:'102px',left:'84%',bottom:'25%',position:'absolute'}}/>
      <Box component='img' src={red} sx={{width:'300px',display:{md:'block',xs:'none'},height:'300px',borderRadius:'100%',left:'30%',bottom:'0%',position:'absolute'}}/>
      <Box component='img' src={green}  sx={{width:'300px',display:{md:'block',xs:'none'},height:'300px',borderRadius:'100%',right:'0%',top:'0%',position:'absolute'}}/>
    <Box component="img" src={trend} sx={{width:'147px',height:'158px'}}/>
    <Box>
      <Typography sx={{fontWeight:'500',fontSize:{md:'56px',xs:'27px'},fontFamily:'Montserrat',textAlign:'center'}}>Shop everything you need</Typography>
      <Typography sx={{textAlign:'center'}}>
      <Typography component='span' sx={{fontSize:{md:'56px',xs:'27px'},fontFamily:'Montserrat',fontWeight:'500',color:'#ff944E'}}>Bharat</Typography>
      <Typography component='span' sx={{fontSize:{md:'56px',xs:'27px'},fontFamily:'Montserrat',fontWeight:'500',color:'#455F76'}}>Ki Apni</Typography>
      <Typography component='span' sx={{fontSize:{md:'56px',xs:'27px'},fontFamily:'Montserrat',fontWeight:'500',color:'#8cb89f'}}>Bharat</Typography>
      </Typography>
  <Typography sx={{fontFamily:'montserrat',fontWeight:{md:'500',xs:'400'},fontSize:{md:'22px',xs:'16px'},lineHeight:{md:'35px',xs:'28px'},textAlign:'center'}}>Dont miss out on this winter deal-bring joy to your family today! Shop from a wide range of products,perfect for every need and occasion</Typography>
    </Box>
    </Box>
    </Box>

  )
}

export default ShowEverything