"use client";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { Box, IconButton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SlideCard from "./SlideCard";

export default function Hero() {
  // Initialize the slider and get the ref and instanceRef
  const [sliderRef, instanceRef] = useKeenSlider(
    {
      loop: true,
    },
    [
      (slider) => {
        let timeout;
        let mouseOver = false;

        const clearNextTimeout = () => {
          clearTimeout(timeout);
        };

        const nextTimeout = () => {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            if (instanceRef.current) {
              instanceRef.current.next();
            }
          }, 2000);
        };

        slider.on("created", () => {
          slider.container.addEventListener("mouseover", () => {
            mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener("mouseout", () => {
            mouseOver = false;
            nextTimeout();
          });
          nextTimeout();
        });

        slider.on("dragStarted", clearNextTimeout);
        slider.on("animationEnded", nextTimeout);
        slider.on("updated", nextTimeout);
      },
    ]
  );
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        padding: { xs: "16px", md: "56px" },
      }}
    >
      {/* Slider */}
      <Box ref={sliderRef} className="keen-slider" sx={{ width: "100%" }}> 
        <Box className="keen-slider__slide">
<SlideCard shoesInd={0} />
        </Box>      
        <Box className="keen-slider__slide">
<SlideCard shoesInd={1} />
        </Box>      
        {/* <Box className="keen-slider__slide">
<SlideCard shoesInd={2} />
        </Box>      
        <Box className="keen-slider__slide">
<SlideCard shoesInd={3} />
        </Box>       */}
       </Box> 
      <Box
        onClick={() => instanceRef.current && instanceRef.current.prev()}
        sx={{
          display:'flex',
          alignItems:'center',
          position: "absolute",
          top: "50%",
          left: {md:"2.5%",xs:'1px'},
          borderRadius:'50%',
          transform: "translateY(-50%)",
          backgroundColor: "primary.main",
          color: "white", 
          padding:'5px',
          "&:hover": {
            backgroundColor: "primary.main",
          },
        }}
        aria-label="Previous Slide"
      >
        <ArrowBackIosIcon />
      </Box>
  
      
      <Box
        onClick={() => instanceRef.current && instanceRef.current.next()}
        sx={{
          position: "absolute",
          top: "50%",
          borderRadius:'50%',
          padding:'5px',
          right: {md:"2.5%",xs:'1px'},
          display:'flex',
          alignItems:'center',
          transform: "translateY(-50%)",
          backgroundColor: "primary.main",
          color: "white", 
          "&:hover": {
            backgroundColor: "primary.main",
          },
        }}
        aria-label="Next Slide"
      >
        <ArrowForwardIosIcon />
      </Box>
    </Box>
  );
}
