import React, { useEffect, useState } from "react";

import PlayArrowIcon from "@mui/icons-material/PlayArrow";

import { Box, Typography } from "@mui/material";
import { get } from "../../services";
import { IN_PRODUCT, IN_URL, PROD_IMG_FUNC } from "../../BaseUrl";
import SingleVideo from "./SingleVideo";





const ClotheSection = () => {
    const [youMayLike,setYouMayLike]=useState();
    const [highlight,setHighlight]=useState();
    const [trend,setTrend]=useState();  
    const [productData,setProductData]=useState([
        {
          id: 1,
          title: "You may also like",
          trend: false,
          detail: [
          ],
        },
        {
          id: 2,
          title: "Keep Shopping with us",
          trend: false,
          detail: [
          ],
        },
        {
          id: 3,
          title: "Explore videos to know Product",
          trend: true,
          detail: [
          ],
        },
      ]);
   
    useEffect(() => {
        const params = {
          highlights: "1",
          limit: 4,
        };
        get(IN_PRODUCT + "get-products", { params })
          .then((res) => {
            const { data } = res;
            console.log(data);
            setHighlight(data.data);
            const temp=[...productData];
            temp[0].detail=data.data;
            setProductData(temp);
            // console.log(
            //   PROD_IMG_FUNC(data.data[1].User.secure_id) +
            //     data.data[1].thumbnail_image_url
            // );
          })
          .catch((e) => {
            console.log(e);
          });

          
          
      }, []);

    useEffect(() => {
        const params = {
          is_daily_deals: "1",
          limit: 4,
        };
        get(IN_PRODUCT + "get-products", { params })
          .then((res) => {
            const { data } = res;
            console.log(data);
            setYouMayLike(data.data);
            const temp=[...productData];
            temp[1].detail=data.data;
            setProductData(temp);
            // console.log(
            //   PROD_IMG_FUNC(data.data[1].User.secure_id) +
            //     data.data[1].thumbnail_image_url
            // );
          })
          .catch((e) => {
            console.log(e);
          });

          
          
      }, []);

        useEffect(()=>{
          const params={
            limit:4
          }
          get(IN_URL+'videos',{params}).then((res)=>{
            const {data}=res;
            setTrend(data.data);
            const temp=[...productData];
            temp[2].detail=data.data;
            setProductData(temp);
          }).catch((e)=>{
      
          })
        },[]);




  return (
    <Box sx={{ width: "100%", padding: { md: "0px 56px", xs: "0px 16px" } }}>
      <Box
        sx={{
          display: "flex",
          flexWrap: { md: "nowrap", xs: "wrap" },
          alignItems: "center",
          justifyContent: "center",
          gap: "24px",
        }}
      >
        {productData?.map((data) => (
          <Box
            key={data.title}
            sx={{
              width: "100%",
              position: "relative",
              padding: { md: "20px 24px", xs: "14px" },
              gap: "15px",
              backgroundColor: "primary.contrastText",
              boxShadow: "0px 7.39px 14.78px -2.46px rgba(145, 158, 171, 0.12)",
              height:"670px",
              mb:3
            }}
          >
            {data.trend && (
              <Box
                sx={{
                  backgroundColor: "success.main",
                  width: "fit-content",
                  padding: "4px 7px",
                  position: "absolute",
                  top: "0%",
                  right: "0%",
                  fontFamily: "Montserrat",
                }}
              >
                <Box
                  component="span"
                  sx={{
                    color: "primary.contrastText",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  Trend's
                </Box>
              </Box>
            )}
            <Typography sx={{ marginBottom: "18px" }}>{data.title}</Typography>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "15px",
              }}
            >
              {data.detail.map((detail) => (
                <Box
                  key={detail?.product_name}
                  sx={{
                    display: "flex",
                    position: "relative",
                    gap: { md: "15px", xs: "13px" },
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    
                  }}
                  
                >
                  {data.trend && (
                    <PlayArrowIcon
                      sx={{
                        position: "absolute",
                        top: "30%",
                        left: "40%",
                        color: "primary.contrastText",
                      }}
                    />
                  )}
                  {data.trend?(
                    <Box sx={{width:150}}>
                    <SingleVideo vid={detail} ratio={"4/5"} radius={1}/>
                    </Box>
                  ):(
                            <Box
                            sx={{
                            // width: { md: "174px", xs: "126px" },
                            objectFit:"cover",
                            height: { md: "180px", xs: "147px" },
                            '&:hover':{
                                cursor:"pointer",
                              }
                            }}
                            component="img"
                            src={PROD_IMG_FUNC(detail?.User?.secure_id) +
                                detail?.thumbnail_image_url}
                            onClick={(e) => window.open(`/product/${detail.id}`, '_blank')}
                        />
                        )}
                  
                  <Typography
                    sx={{
                      fontSize: { md: "16px", xs: "14px" },
                      fontWeight: "500px",
                    }}
                  >
                    {detail?.product_name?.substr(0,50)|| detail?.title?.substr(0,20) }...
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ClotheSection;
