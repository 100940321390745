import { Box, Divider, Link, Typography } from "@mui/material";
import React from "react";
import { IN_ASSETS } from "../../BaseUrl";
import { useNavigate } from "react-router-dom";
// const data = [IN_ASSETS+"homepage-banners/Banner%201.png", IN_ASSETS+"homepage-banners/Banner%202.png", yellowShoes, whiteShoes];

const data=[
  {
    imageUrl:IN_ASSETS+"homepage-banners/Banner%201.png",
    link:"/registerseller"
  },
  {
    imageUrl:IN_ASSETS+"homepage-banners/Banner%202.png",
    link:"/campaign"
  },
  {
    imageUrl:IN_ASSETS+"/banner%203.png",
    link:"/dukan"
  }
]

const SlideCard = ({ shoesInd }) => {
  const navigate=useNavigate();
  return (
    <Link
      // sx={{
      //   width: "100%",
      //   padding: { md: "13px 0px", xs: "14px 8px" },
      //   borderRadius: { xs: "3px", md: "10px" },
      //   display: "flex",
      //   alignItems: "center",
      //   gap: { md: "42px", xs: "10px" },
      //   justifyContent: "center",
      //   backgroundColor: "#F7F7F7",
      // }}
      // onClick={navigate(data[shoesInd].link)}
  
      href={data[shoesInd].link} target="_blank" rel="noopener noreferrer"
    >
      {/* <Box
        sx={{
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
          gap: { md: "12px", xs: "7px", flexDirection: "column" },
        }}
      >
        <Typography
          sx={{
            color: "primary.main",
            fontSize: { md: "48px", xs: "23px" },
            fontWeight: "400",
            lineHeight: "22px",
            fontFamily: "Bebas Neue",
          }}
        >
          NIKE AIR MAX 90 FUTURE
        </Typography>
        <Typography
          sx={{
            fontSize: { md: "14px", xs: "9px" },
            fontWeight: {
              md: "400",
              xs: "300px",
              textAlign: "justify",
              lineHeight: "15px",
            },
          }}
        >
          The Nike Air Max 90 Future re-imagines the icon of Air through your
          eyes-from design to testing to styling
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "4px",
          }}
        >
          <Box
            component="img"
            src={pinkShoes}
            sx={{
              width: { md: "115px", xs: "50px" },
              height: { md: "60px", xs: "26px" },
            }}
          />
          <Divider
            orientation="vertical"
            sx={{ borderColor: "#BABABA", height: { md: "60px", xs: "26px" } }}
          />
          <Box
            component="img"
            src={yellowShoes}
            sx={{
              width: { md: "115px", xs: "50px" },
              height: { md: "60px", xs: "26px" },
            }}
          />
          <Divider
            orientation="vertical"
            sx={{ borderColor: "#BABABA", height: { md: "60px", xs: "26px" } }}
          />
          <Box
            component="img"
            src={lightshoes}
            sx={{
              width: { md: "115px", xs: "50px" },
              height: { md: "60px", xs: "26px" },
              display: { md: "block", xs: "none" },
            }}
          />
          <Divider
            orientation="vertical"
            sx={{
              borderColor: "#BABABA",
              height: { md: "60px", xs: "26px" },
              display: { md: "block", xs: "none" },
            }}
          />
          <Typography
            sx={{
              fontSize: { md: "14px", xs: "6px" },
              fontWeight: "500",
              textDecoration: "underline",
              color: "primary.main",
            }}
          >
            SEE ALL
          </Typography>
        </Box>
      </Box>
      <Divider
        orientation="vertical"
        sx={{ borderColor: "#BABABA", height: { md: "207px", xs: "110px" } }}
      /> */}

      <Box
        src={data[shoesInd].imageUrl}
        sx={{
          // width: { md: "370px", xs: "197px" },
          // height: { md: "210px", xs: "110px" },
          width:"100%",
          '&:hover':{
            cursor:"pointer"
          }
        }}
        
        component="img"
      />
    </Link>
  );
};

export default SlideCard;
