import { Box, Typography } from "@mui/material";
import React from "react";
import { IN_ASSETS } from "../../BaseUrl";
import { useNavigate } from "react-router-dom";

const data = [
  {
    title: "Grocery & Food",
    img: IN_ASSETS + "homepage-icons/Grocery.png",
    slug:'grocery-and-food',
  },
  {
    title: "Electronics & Accessories",
    img: IN_ASSETS + "homepage-icons/Electronics-in-focus.png",
    slug:'electronic',
  },
  {
    title: "Clothing, Fashion & Accessories",
    img: IN_ASSETS + "homepage-icons/Cloths.png",
    slug:'clothing',
  },
  {
    title: "Kitchen Appliances",
    img: IN_ASSETS + "homepage-icons/Kitchen%20Appliances.png",
    slug:'kitchen-appliances'
  },
];

const InFocus = () => {

    const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: "100%",
        padding: "0px 86px",
        display: { md: "block", xs: "none" },
      }}
    >
      <Box>
        <Typography
          sx={{
            marginBottom: "32px",
            textAlign: "center",
            fontSize: "40px",
            fontWeight: "600px",
            fontFamily: "Montserrat",
          }}
        >
          In Focus Categories
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "40px",
            flexWrap: { md: "nowrap", xs: "wrap" },
          }}
        >
          {data.map((data) => (
            <Box
              key={data.title}
              sx={{
                height: "400px",
                padding: "29px 42px",
                background:
                  "radial-gradient(50% 50% at 50% 50%, rgba(213, 224, 255, 0) 0%, #D5E0FF 100%)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "30px",
                borderRadius: "8.86px",
                "&:hover":{
                    cursor:'pointer'
                }

              }}
              onClick={(e)=>navigate('/category/'+data.slug)}
            >
              <Typography
                sx={{
                  fontSize: "28px",
                  fontWeight: "600",
                  color: "primary.main",
                  textAlign: "center",
                }}
              >
                {data.title}
              </Typography>
              <Box
                component="img"
                src={data.img}
                sx={{  height: "210px" }}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default InFocus;
