import { Box, Divider, Typography } from '@mui/material'
import React from 'react'
import indiazonaIcon from '../../assets/indianzonaIcon.svg'
import lionIcon from '../../assets/lionIcon.svg'
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Drawer from '@mui/material/Drawer';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import Person2Icon from '@mui/icons-material/Person2';

const MobileHeader = () => {
  
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const DrawerList = (
    <Box sx={{ width: 200,display:'flex',padding:'23px 18px',alignItems:'flex-start',gap:"39px"}} role="presentation" onClick={toggleDrawer(false)}>
        <MenuOpenIcon onClick={toggleDrawer(true)} sx={{width:'31px',height:'31px',color:'primary.main'}}/>
        <Box sx={{display:'flex',alignItems:'start',gap:'20px',flexDirection:'column'}}>
        <Box sx={{display:'flex',alignItems:'center',justifyContent:'center',gap:"2px"}}>
    <FavoriteBorderIcon sx={{width:'17px',height:'17px'}}/>
    <Typography sx={{fontSize:'14px',fontWeight:'500',fontFamily:'Montserrat'}}>Wishlist</Typography>
  </Box>
        <Box sx={{display:'flex',alignItems:'center',justifyContent:'center',gap:"2px"}}>
    <LocalMallOutlinedIcon sx={{width:'17px',height:'17px'}}/>
    <Typography sx={{fontSize:'14px',fontWeight:'500',fontFamily:'Montserrat'}}>Cart</Typography>
  </Box>
        <Box sx={{display:'flex',alignItems:'center',justifyContent:'center',gap:"2px"}}>
    <Person2Icon sx={{width:'17px',height:'17px'}}/>
    <Typography sx={{fontSize:'14px',fontWeight:'500',fontFamily:'Montserrat'}}>Profile</Typography>
  </Box>
        </Box>
 
    </Box>
  );
  return (
    <> 
      <Drawer  anchor="right"  open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    <Box sx={{padding:'13px',width:"100%",display:{xs:"flex",md:'none'},alignItems:'center',backgroundColor:'background.paper',justifyContent:'space-between',height:'fit-content'}}>
    <Box sx={{display:'flex',alignItem:'center',gap:"10px",width:'fit-content',height:'fit-content'}}>
      <Box sx={{display:'flex',alignItem:'center',width:"128px",height:'44px',overflow:'hidden'}}>
      <img alt='indiazona'  src={indiazonaIcon}/>
      </Box>
    <Divider sx={{width:'1px',backgroundColor:"background.grey"}}/>
      <Box sx={{display:'flex',alignItem:'center',width:"94px",height:'48px',overflow:'hidden'}}>
      <img alt='lionIcon' src={lionIcon}/>
      </Box>
    </Box>
    <Box>
        <Box sx={{width:'31px',height:'fit-content',cursor:'pointer'}}>
        <MenuOpenIcon onClick={toggleDrawer(true)} sx={{overflow:'hidden',width:'31px',height:'31px',color:'primary.main'}}/>

        </Box>
    </Box>
    
  </Box>
  </>

  )
}

export default MobileHeader