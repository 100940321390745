import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import dukan from "../../assets/subHeader/bhartdukan.gif";
import trend from "../../assets/subHeader/trending.svg";
import { useNavigate } from "react-router";
import { IN_ASSETS } from "../../BaseUrl";
const data = [
  { title: "Electronic", img: IN_ASSETS+"homepage-icons/Electronics.png" ,slug:'electronic' },
  { title: "Home Appliances", img: IN_ASSETS+"homepage-icons/Home%20epliances.png" ,slug:'home-appliances' },
  { title: "Organic Superfoods", img: IN_ASSETS+"homepage-icons/organic%20super%20food.png" ,slug:'organic-superfoods'  },
  { title: "Sarees", img: IN_ASSETS+"homepage-icons/Saree.png" ,slug:'sarees'  },
  { title: "Ethnic Wear", img: IN_ASSETS+"homepage-icons/Ethenic.png" ,slug:'ethnic-wear'  },
  { title: "Baby Fashion", img: IN_ASSETS+"homepage-icons/Baby.png" ,slug:'baby-fashion' },
  { title: "Kitchen Appliances", img: IN_ASSETS+"homepage-icons/Kitchen.png" ,slug:'kitchen-appliances'  },
  { title: "Handmade Items", img: IN_ASSETS+"homepage-icons/Handmade.png" ,slug:'handmade-items' },
  { title: "Beauty Appliances", img: IN_ASSETS+"homepage-icons/Health%20&%20Beauty.png" ,slug:'beauty-appliances'  },
];

const SubHeader = () => {
  const navigate = useNavigate();
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          padding: { md: "20px 31px", xs: "8px 0px" },
          display: "flex",
          margin: { md: "32px 56px 0px 56px", xs: "16px 16px" },
          gap: { md: "31px", xs: "9px" },
          alignItems: "center",
          justifyContent: { md: "space-between" },
          backgroundColor: "primary.contrastText",
          height: "fit-content",
          borderRadius: "10px",
        }}
      >
        <Box
          component="img"
          src={dukan}
          onClick={() => navigate("/dukan")}
          alt="bhartdukan"
          sx={{
            width: { md: "97px", xs: "60px" },
            height: { md: "104px", xs: "64px" },
            '&:hover':{
                  cursor:"pointer",
                }
          }}
        />

        <Box
          component="img"
          src={trend}
          alt="trend"
          sx={{
            width: { md: "96px", xs: "57px" },
            height: { md: "96px", xs: "57px" },
          }}
        />
        <Divider
          orientation="vertical"
          sx={{
            width: { md: "2px", xs: "1px" },
            backgroundColor: "background.grey",
            height: { md: "96px", xs: "57px" },
          }}
        />
        <Box
          sx={{
            display: "flex",
            gap: "31px",
            justifyContent: "space-between",
            width: { md: "100%", xs: "80%" },
            // Hide scrollbar for Webkit browsers (Chrome, Safari)
            "&::-webkit-scrollbar": {
              display: "none",
            },
            // Hide scrollbar for IE, Edge, and Firefox
            "-ms-overflow-style": "none", // IE and Edge
            "scrollbar-width": "none", // Firefox
            overflow: { xs: "scroll", md: "hidden" },
          }}
        >
          {data.map((data) => (
            <Box
              key={data.title}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start !important",
                '&:hover':{
                  cursor:"pointer"
                }
              }}
              onClick={(e)=>navigate('/category/'+data.slug)}
              // border={1}
            >
              <Box
                sx={{
                  padding: { md: "6px 15px", xs: "4px 9px" },
                  border: "1px solid",
                  borderColor: "primary.main",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "flex-start !important",
                  overflow: "hidden",
                  justifyContent: "center",
                  width: { md: "67px", xs: "42.5px" },
                  height: { md: "67px", xs: "42.5px" },
                }}
              >
                <Box
                  component="img"
                  src={data.img}
                  alt={data.title}
                  sx={{
                    // width: { md: "36px", xs: "23px" },
                    // height: { md: "53px", xs: "35px" },
                    // width:"100%",
                    height:"100%",
                    objectFit:"cover",
                    mt:0,
                  }}
                />
              </Box>
              <Typography
                sx={{
                  color: "primary.main",
                  fontSize: { md: "16px", xs: "12px" },
                  textAlign:'center',
                  mt:1,
                }}
              >
                {data.title}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default SubHeader;
