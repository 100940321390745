import { Box } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../components/HomePage/Header'
import Footer from '../components/HomePage/Footer'

const HomePageLayout = () => {
  return (
    <Box
      sx={(theme) => ({
        paddingTop: theme.spacing(6),
        background: `linear-gradient(180deg, ${theme.palette.primary.light} 0%, ${theme.palette.customBg.white} 10%)`,
      })}
    >
        <Header />
        <Outlet/>
        <Footer />
    </Box>
  )
}

export default HomePageLayout
