import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Radio,
  Button,
  TextField,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Modal,
  Stack,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import { Edit } from '@mui/icons-material';
import { get, put } from "../../services";
import { BASE_URL, IZ_USER } from "../../BaseUrl";
import { useFormik } from "formik";
import { ConfirmPassword, phone_number, postal_code, required } from "../../utils/validations";

import * as Yup from "yup";
import { toast } from "react-toastify";

const DeliveryAddress = ({setAddressId}) => {
  const [showForm, setShowForm] = useState(false);
  
  const [addresses, setAddresses] = useState([]);
  const [editIndex, setEditIndex] = useState(null); // Track index for editing
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(null);
  const [stateOption, setStateOption] = useState([]);
  const [cityOption, setCityOption] = useState([]);
  const [loading, setLoading] = useState(false);

  const [stateValue, setStateValue] = useState(null);
  const [cityValue, setCityValue] = useState(null);

  const [ctyErr, setCtyErr] = useState(false);
  const [stErr, setStErr] = useState(false);
  const[refresh,setRefresh]=useState(1);
  
  const [address, setAddress] = useState({
    name: '',
    mobile: '',
    pincode: '',
    locality: '',
    areaStreet: '',
    city: '',
    state: '',
    landmark: '',
    Altmobile: '',
    addressType: '', // Default to an empty string
  });
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddress((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    if (Object.keys(address).length > 0) {
      if (editIndex !== null) {
        // Update existing address
        setAddresses((prev) =>
          prev.map((addr, index) => (index === editIndex ? address : addr))
        );
      } else {
        // Add new address
        setAddresses((prev) => [...prev, address]);
      }
    }
    setShowForm(false);
    setAddress({});
    setEditIndex(null);
  };

  const handleCancel = () => {
    setShowForm(false);
    setAddress({});
    setEditIndex(null);
  };

  const handleEdit = (index) => {
    setAddress(addresses[index]); // Load the selected address into the form
    setEditIndex(index); // Set the edit index
    setShowForm(true); // Open the form modal
  };

  useEffect(()=>{
    get(IZ_USER+'get-address').then((res)=>{
      const {data}=res;
      setAddresses(data);
    }).catch((e)=>{
      console.log(e);
    })
  },[refresh]);

  useEffect(()=>{


    // get('/countries').then((res)=>{
    //     const {data}=res;
    //     setCountryOption(data.data);
    // }).catch((e)=>{
    //     toast.error("Something went wrong please try again later");
    //     console.error(e);
    // })
    
    get(BASE_URL+'/states').then((res)=>{
        const {data}=res;
        setStateOption(data.data);
        // console.log(stateOption);
    }).catch((e)=>{
        // toast.error("Something went wrong please try again later");
        console.error(e);
    })

  },[]);
  useEffect(()=>{
    if(stateValue) {
        get(BASE_URL+`/cities/${stateValue.id}`).then((res)=>{
            const {data}=res;
            setCityOption(data.data);

        }).catch((e)=>{
            // toast.error("Something went wrong please try again later");
            console.error(e);
        })
    }
  },[stateValue])

  const pickupSchema = Yup.object({
    whManager:required,
    flatAndStreet:required,
    whContact: phone_number,
    flatAndStreet:required,
    landmark:required,
    pinCode:postal_code,

});

const onSubmit=()=>{
  setLoading(true);
if(!stateValue){
  setLoading(false);
    setStErr(true);
    return;
}
if(!cityValue){
  setLoading(false);
    setCtyErr(true);
    return;
}
const payload={
    "manager_name":values.whManager,
    "phone":values.whContact,
    "address":values.flatAndStreet,
    "country_id":'101',
    "state_id":stateValue.id,
    "city_id":cityValue.id,
    "postal_code":values.pinCode,
    "landmark":values.landmark,
    "id":"",
}

  put(IZ_USER+'update-address',payload).then((res)=>{
    const {data}=res;
    setLoading(false);
    setRefresh(refresh+1);
    handleCancel();
  }).catch((e)=>{
    setLoading(false);
    toast.error("Please create an account or login")
    window.location.href = "/login";
    
    console.log(e);
  })



}

  const {values,touched,handleBlur,handleChange,errors,setFieldValue,handleSubmit,resetForm}=useFormik({
    initialValues:{
        whManager:"",
        whContact:"",
        flatAndStreet:"",
        landmark:"",
        pinCode:"",
        id:"",
        
    },
    validationSchema: pickupSchema,
    onSubmit,

  });

  return (
    

    <Box
      sx={{
        maxWidth: "884px",
        backgroundColor: "#fff",
       
        "@media (max-width: 600px)": {
          padding: "5px",
        },
      }}
    >
     <Typography
  sx={{
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: 600,
    marginBottom: "16px",
    lineHeight: '29.26px',
    textUnderlinePosition: 'from-font',
    color: "#455F76",
    "@media (max-width: 600px)": {
      fontSize: "20px",
    },
  }}
>
  Select Delivery Address
</Typography>

      {addresses.length > 0 ? (
        addresses?.map((address, index) => (
          <Box
            key={index}
            sx={{
              padding: "16px",
              border: "1px solid #BABABA",
              borderRadius: "8px",
              marginBottom: "15px",
              backgroundColor: "border: 1px solid var(--iz-grey-light, #BABABA)",
              
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "8px",
                "@media (max-width: 600px max-height: auto)": {
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  "@media (max-width: 600px)": {
                    flexDirection: "row",
                    alignItems: "center",
                  },
                }}
              >
                {/* Updated Radio Button */}
                <Radio
                  checked={selectedAddressIndex === index}
                  onChange={() => {
                    setSelectedAddressIndex(index);
                    setAddressId(address.id);
                  }}
                />
                <Typography sx={{ fontSize: "16px", fontFamily: "Montserrat", fontWeight: "600" }}>
                  {address.manager_name}
                </Typography>
                {address.addressType && (
  <Box
    sx={{
      width: "56px",
      height: "25px",
      position: "relative",
      
      padding: "4px 6px",
      gap: "10px",
      borderRadius: "3px 3px 3px 3px",
      border: "0.5px solid #455F76", // Add border color
      opacity: 1, // Make it visible
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "17.07px",
      textAlign: "left",
      textUnderlinePosition: "from-font",
      textDecorationSkipInk: "none",
      color: "#455F76",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    {address.addressType}
  </Box>
)}

                <Typography sx={{ fontSize: "16px", fontFamily: "Montserrat", fontWeight: "600" }}>
                  {address.phone}
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  
                  cursor: "pointer",
                  color: '#455F76',
                  "@media (max-width: 600px)": {
                    marginLeft: "8px",
                  },
                }}
                onClick={() => handleEdit(index)}
              >
              <Edit sx={{ color: '#455F76',width:'15px',height:'15px' }} />   Edit
              </Typography>
            </Box>
            <Typography
              sx={{
                fontFamily: "Montserrat",
                marginLeft: "33px",
                marginTop: '0px',
                fontSize: "16px",
                lineHeight: "24px",
                color: "#656565",
                fontWeight: "500",
                paddingLeft: "16px",
                
                "@media (max-width: 600px)": {
                  marginLeft: "0",
                },
              }}
            >
              {`${address.address }, ${address.landmark}, ${address.city.name}, ${address.state.name}, ${address.postal_code}`}
            </Typography>
          </Box>
        ))
      ) : (
        <Typography 
  sx={{ marginBottom: '16px', fontFamily: 'Montserrat', fontSize: '16px', color: '#656565' }}
>
  No addresses added yet.
</Typography>

      )}
    
    
<a
  href="#"
  onClick={() => setShowForm(true)}
  style={{
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '19.5px',
    textAlign: 'left',
    marginTop: '60px',
    textDecorationLine: 'underline',
    textDecorationStyle: 'solid',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
    cursor: 'pointer',
    color:  '#FF944E',
   

  }}
>
  Add New Delivery Address
</a>
<hr
  style={{
    width: '100%',  // Make the line take up 100% of the width of its container
    maxWidth: '896px',  // Limit the width to 896px on larger screens
    margin: '16px auto',  // Center the line horizontally and add vertical spacing
    border: '0',  // Remove default border style
    borderTop: '2px solid #BABABA',  // Set color and thickness for the horizontal line
  }}
/>



<Modal
  open={showForm}
  onClose={handleCancel}
  aria-labelledby="add-address-modal"
  sx={{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }}
>
  <Box
    sx={{
      backgroundColor: "#fff",
      borderRadius: "10px",
      padding: "24px",
      width: "90%",
      maxWidth: "848px",
      maxHeight: "650px", // Limit modal height to viewport height
      overflowY: "auto", // Enable scrolling for overflow
      boxShadow: 24,
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    }}
  >
    <Typography
      variant="h6"
      sx={{
        fontWeight: "600",
        marginBottom: "16px",
        textAlign: "center",
      }}
    >
      {editIndex !== null
        ? "Edit Delivery Address"
        : "Add New Delivery Address"}
    </Typography>

    {/* Address Form */}
    {/* <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: "10px",
        height: "750px",
        "@media (max-width: 600px)": {
          gridTemplateColumns: "1fr",
        },
      }}
    >
      <TextField
        label="Name"
        name="name"
        value={address.name || ""}
        onChange={handleInputChange}
        fullWidth
      />
      <TextField
        label="Mobile Number"
        name="mobile"
        value={address.mobile || ""}
        onChange={handleInputChange}
        fullWidth
      />
      <TextField
        label="Pincode"
        name="pincode"
        value={address.pincode || ""}
        onChange={handleInputChange}
        fullWidth
      />
      <TextField
        label="Locality"
        name="locality"
        value={address.locality || ""}
        onChange={handleInputChange}
        fullWidth
      />
      <TextField
        label="Area & Street"
        name="areaStreet"
        value={address.areaStreet || ""}
        onChange={handleInputChange}
        fullWidth
        sx={{
          gridColumn: "1 / -1", // Spans full row
          "& .MuiInputBase-root": {
            height: "76px",
          },
        }}
      />
      <TextField
        label="City"
        name="city"
        value={address.city || ""}
        onChange={handleInputChange}
        fullWidth
      />
      <TextField
        label="State"
        name="state"
        value={address.state || ""}
        onChange={handleInputChange}
        fullWidth
      />
      <TextField
        label="Landmark"
        name="landmark"
        value={address.landmark || ""}
        onChange={handleInputChange}
        fullWidth
      />
      <TextField
        label="Alternate Mobile"
        name="Altmobile"
        value={address.Altmobile || ""}
        onChange={handleInputChange}
        fullWidth
      />
    </Box>

    <FormControl
 
>
  <Typography
    sx={{
      fontFamily: "Montserrat",
      fontSize: "16px",
      color: "#A1A1A1",
      fontWeight: "500",
      marginBottom: "8px",
    }}
  >
    Address Type
  </Typography>
  <RadioGroup
    name="delivery-options"
    value={address.addressType || ""}
    onChange={(e) =>
      setAddress((prev) => ({
        ...prev,
        addressType: e.target.value,
      }))
    }
    sx={{
      display: "flex",
      flexDirection: "row",
      gap: "16px",
      "@media (max-width: 600px)": {
        flexDirection: "column",
      },
    }}
  >
    <FormControlLabel
      value="home"
      control={<Radio />}
      label={
        <Box>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: "17px",
              color: "#212121",
              fontWeight: "600",
            }}
          >
            Home
          </Typography>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: "14px",
              color: "#B8B8B8",
              fontWeight: "400",
            }}
          >
            Home deliveries
          </Typography>
        </Box>
      }
    />
    <FormControlLabel
      value="work"
      control={<Radio />}
      label={
        <Box>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: "17px",
              color: "#212121",
              fontWeight: "600",
            }}
          >
            Work
          </Typography>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: "14px",
              color: "#B8B8B8",
              fontWeight: "400",
            }}
          >
            Office deliveries
          </Typography>
        </Box>
      }
    />
  </RadioGroup>
  
</FormControl> */}

<form onSubmit={handleSubmit} autoComplete="off" >
        {/* Row 1: account number------------------------------------------------------------------------------------------------------------------------------------- */}
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          {/* Account Number */}
          <Stack spacing={1} sx={{ flex: 1 }}>
            <Typography variant="body1" sx={{fontSize:"14px"}}>Name<Typography component="span" sx={{color:"red"}}>*</Typography></Typography>
            <TextField fullWidth variant="outlined" name="whManager" value={values.whManager}  onChange={handleChange} onBlur={handleBlur} error={errors.whManager && touched.whManager} helperText={errors.whManager && touched.whManager?errors.whManager :""} />
          </Stack>

          {/* confirm Account Number */}
          <Stack spacing={1} sx={{ flex: 1 }}>
            <Typography variant="body1" sx={{fontSize:"14px"}}>Phone Number<Typography component="span" sx={{color:"red"}}>*</Typography></Typography>
            <TextField fullWidth variant="outlined" name="whContact" value={values.whContact}  onChange={handleChange} onBlur={handleBlur} error={errors.whContact && touched.whContact} helperText={errors.whContact && touched.whContact?errors.whContact :""}   />
          </Stack>
        </Stack>


        {/* Row 2:Beneficiary Name Bank Name------------------------------------------------------------------------------------------------------------------------------------- */}
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          {/* Account Number */}
          <Stack spacing={1} sx={{ flex: 1 }}>
            <Typography variant="body1" sx={{fontSize:"14px"}}>Flat/Street name <Typography component="span" sx={{color:"red"}}>*</Typography></Typography>
            <TextField fullWidth variant="outlined" name="flatAndStreet" value={values.flatAndStreet}  onChange={handleChange} onBlur={handleBlur} error={errors.flatAndStreet && touched.flatAndStreet} helperText={errors.flatAndStreet && touched.flatAndStreet?errors.flatAndStreet :""}   />
          </Stack>

          {/* confirm Account Number */}
          <Stack spacing={1} sx={{ flex: 1 }}>
            <Typography variant="body1" sx={{fontSize:"14px"}}>Landmark <Typography component="span" sx={{color:"red"}}>*</Typography></Typography>
            <TextField fullWidth variant="outlined" name="landmark" value={values.landmark}  onChange={handleChange} onBlur={handleBlur} error={errors.landmark && touched.landmark} helperText={errors.landmark && touched.landmark?errors.landmark :""}   />
          </Stack>
        </Stack>
        {/* Row 1: IFSC Code and Bank Branch------------------------------------------------------------------------------------------------------------------------------------- */}
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          {/* Account Number */}
          <Stack spacing={1} sx={{ flex: 1, mt: 2.5 }}>
              <Typography variant="body1" sx={{ fontSize: "14px" }}>
                Country{" "}
                <Typography component="span" sx={{ color: "red" }}>
                  *
                </Typography>
              </Typography>
              {/* <Autocomplete
                    options={countryOption}
                    getOptionLabel={(option) => option.name} // Use the 'label' property for display
                    value={countryValue}
                    onChange={(event, newValue) => {
                        setCtyErr(false);
                        setCountryValue(newValue)}}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Select an option"
                        placeholder="Search..."
                    />
                    )}
                    fullWidth
                    disablePortal
                /> */}
                <TextField fullWidth variant="outlined" name="landmark" value="India" disabled    />
                
            </Stack>
            
          {/* confirm Account Number */}
          <Stack spacing={1} sx={{ flex: 1, mt: 2.5 }}>
              <Typography variant="body1" sx={{ fontSize: "14px" }}>
                State{" "}
                <Typography component="span" sx={{ color: "red" }}>
                  *
                </Typography>
              </Typography>
              <Autocomplete
                    options={stateOption}
                    getOptionLabel={(option) => option.name} // Use the 'label' property for display
                    value={stateValue}
                    
                    onChange={(event, newValue) => {
                        setStErr(false);
                        setStateValue(newValue)}}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Select an option"
                        placeholder="Search..."
                    />
                    )}
                    fullWidth
                    disablePortal
                />
                {stErr &&<Typography variant="body1" sx={{fontSize:"12px",color:"#d32f2f"}}>State is required</Typography>}
            </Stack>
        </Stack>

         {/* Row 2:Beneficiary Name Bank Name------------------------------------------------------------------------------------------------------------------------------------- */}
         <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          {/* Account Number */}
          <Stack spacing={1} sx={{ flex: 1, mt: 2.5 }}>
            <Typography variant="body1" sx={{fontSize:"14px"}}>City <Typography component="span" sx={{color:"red"}}>*</Typography></Typography>
            <Autocomplete
                    options={cityOption}
                    getOptionLabel={(option) => option.name} // Use the 'label' property for display
                    value={cityValue}
                    onChange={(event, newValue) => {
                        setCtyErr(false);
                        setCityValue(newValue)}}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Select an option"
                        placeholder="Search..."
                    />
                    )}
                    fullWidth
                    disablePortal
                />
                {ctyErr &&<Typography variant="body1" sx={{fontSize:"12px",color:"#d32f2f"}}>City is required</Typography>}
            </Stack>

          {/* confirm Account Number */}
          <Stack spacing={1} sx={{ flex: 1 }}>
            <Typography variant="body1" sx={{fontSize:"14px"}}>Pin Code <Typography component="span" sx={{color:"red"}}>*</Typography></Typography>
            <TextField fullWidth variant="outlined" name="pinCode" value={values.pinCode}  onChange={handleChange} onBlur={handleBlur} error={errors.pinCode && touched.pinCode} helperText={errors.pinCode && touched.pinCode?errors.pinCode :""}   />
          </Stack>
        </Stack>
        <Stack direction="row" fullWidth>
        {/* <Button
            type="submit"
            variant="contained"
            color="secondary"
            sx={{ mt: 1, px: 3, textTransform: 'none',width:"100px" }}
        >
                {loading?<CircularProgress size={20} sx={{color:"#FFFFFF"}} /> :"Update"}
        </Button> */}
        </Stack>
            <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "20px",
            marginTop: "16px",
            "@media (max-width: 600px)": {
              flexDirection: "column",
              gap: "12px",
            },
          }}
        >
          <Button
            variant="outlined"
            onClick={handleCancel}
            sx={{
              width: "267px",
              height: "56px",
              fontFamily: 'Montserrat',
              fontWeight:600,
              fontSize: '20px',
              "@media (max-width: 600px)": {
                      width: "100%",
                    },
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            // onClick={handleSave}
            color="secondary"
            type="submit"
            sx={{
              width: "267px",
              height: "56px",
              fontFamily: 'Montserrat',
              fontFamily: 'Montserrat',
              fontWeight:600,
              fontSize: '20px',
              "@media (max-width: 600px)": {
                      width: "100%",
                    },
            }}
          >
            {loading?<CircularProgress size={20} sx={{color:"#FFFFFF"}} /> :"Save"}
          </Button>
        </Box>
        </form>



    {/* Action Buttons */}
    
  </Box>
</Modal>

    </Box>
  );
};

export default DeliveryAddress;
